$light: #dce1ec; // light
$dark: #8ea4c6; // dark
$green: #94c03d;
$pink: #dd1e42;
$primary: #f4faff;
$freeSpeechRed: #b90000;
$sushi-color: #94c03d;
$border-color: #e5e8f7;
$folder-color: #8ea4c6;
$folder-color-hover: #698ec7;
