.video-quality-dialog {
  .video-quality-dialog-title {
    margin-bottom: 10px;
  }

  .video-quality-dialog-contents {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    min-width: 250px;

    .video-quality-dialog-slider-container {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
    }

    .video-quality-dialog-slider {
      width: calc(100% - 5px);

      @mixin sliderTrackStyles() {
        height: 10px;
        border-radius: 10px;
        background: #000000;
      }

      &::-ms-track {
        @include sliderTrackStyles();
      }

      &::-moz-range-track {
        @include sliderTrackStyles();
      }

      &::-webkit-slider-runnable-track {
        @include sliderTrackStyles();
      }

      @mixin sliderThumbStyles() {
        // top: 45%;
        // border: none;
        // position: relative;
        // opacity: 0;
        margin-top: -3px;
      }

      &::-ms-thumb {
        @include sliderThumbStyles();
      }

      &::-moz-range-thumb {
        @include sliderThumbStyles();
      }

      &::-webkit-slider-thumb {
        @include sliderThumbStyles();
      }
    }

    .video-quality-dialog-labels {
      box-sizing: border-box;
      display: flex;
      margin-top: 5px;
      position: relative;
      width: 90%;
    }

    .video-quality-dialog-label-container {
      position: absolute;
      text-align: center;
      transform: translate(-50%, 0%);

      &::before {
        content: '';
        border-radius: 50%;
        left: 0;
        height: 6px;
        margin: 0 auto;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: -16px;
        width: 6px;
      }
    }

    .video-quality-dialog-label-container.active {
      // color: #4C9AFF;

      &::before {
        //  background: #4C9AFF;
        height: 12px;
        top: -19px;
        width: 12px;
      }
    }

    .video-quality-dialog-label-container:first-child {
      position: relative;
    }

    .video-quality-dialog-label {
      display: table-caption;
      word-spacing: unset;
    }
  }
}

.modal-dialog-form {
  .video-quality-dialog-title {
    display: none;
  }
}
