.notification-groups {
  position: fixed;
  top: 40px;
  right: 16px;
  width: 375px;
  z-index: 1301;
  background: transparent;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: white;
  }

  &::-webkit-scrollbar-track {
    background-color: hsla(0,0%,82.7%,.5);
    border-radius: 8px;
    margin: 4px;
  }

  .MuiBanner-root {
    border-left: 3px solid #94c03d;
    margin-top: 2px;
  }
}

.static-banner {
  position: sticky;
  top: 0;
  z-index: 1301;
  .MuiPaper-root {
    background: #94c03d;
    color: white;
  }

  .MuiAvatar-root {
    color: white;
  }

  .MuiButton-root {
    color: white;
  }

  .MuiBanner-cardContent {
    padding-bottom: 16px;
  }
}