// @import './_RKFB.scss';

.create-new-file {
  .upload-btn {
    background-color: #94c03d !important;
  }
}

.share_alert {
  position: absolute;
  top: 10px;
  width: 100%;
  .MuiAlert-root {
    width: 350px;
    margin: auto;
  }
}

.file-table {
  height: calc(100% - 50px);
  .file-browser-container {
    // height: 80vh;
    height: 100%;
  }

  .group-selector {
    margin-bottom: 1rem;
  }

  // margin-top: 80px;
  position: relative;
  font-size: 14px;

  .button-group {
    label {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .file-name {
    color: #212529;
  }
  .action-btn {
    margin: 0 2px;
    svg {
      font-size: 14px;
    }
  }

  .action-button_group {
    position: absolute;
    right: 20px;
    top: 65px;

    .action_button {
      float: right;
      margin: 0 3px;
      color: #fff;
    }

    .delete_button {
      background-color: #dc004e;
    }

    .delete_button:hover {
      background-color: #9a0036;
    }

    .view_button {
      background-color: #1976d2;
    }

    .view_button:hover {
      background-color: #115293;
    }

    .download_button {
      color: #000;
    }
  }

  .react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown {
    margin-left: 7px;
  }

  .react-bootstrap-table-pagination-list {
    .pagination {
      float: right;
    }
    .page-item .page-link {
      color: #94c03d;
      height: 32px;
      min-width: 32px;
      text-align: center;
      margin: 0 3px;
      border-radius: 4px;
      border: solid 1px;
      background: transparent;
      &:hover {
        background-color: #eee;
        border-color: #ddd;
        border: solid 1px #94c03d;
      }
    }
    .active .page-link {
      background-color: #94c03d;
      color: #fff;
      &:hover {
        background-color: #94c03d;
        color: #fff;
      }
    }
  }
}

.upload-file-modal {
  .selected-file-count {
    margin-left: 20px;
  }
  .upload_btn {
    color: #fff;
    margin-bottom: 10px;
  }
}

.group-detail-modal {
  .MuiPaper-root {
    min-width: unset !important;
    max-width: unset;
  }
  .MuiDialogContent-root {
    width: 80vw;
  }
  .MuiListItemIcon-root {
    // width: 180px;
    span {
      color: #000000;
      font-size: 1rem;
      font-family: Roboto, Helvetica Neue, sans-serif;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.file-view-detail-modal {
  &.shared {
    .MuiPaper-root {
      width: 50%;
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: unset;
  }
  .MuiListItemIcon-root {
    width: 220px;
    span {
      color: #000000;
      font-size: 1rem;
      font-family: Roboto, Helvetica Neue, sans-serif;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .group-item,
  .member_item {
    padding: 0;
    .group-tab,
    .member-tab {
      display: flex;
      flex-direction: column;
      .group-name-line,
      .member-name-line {
        display: flex;
        .unit {
          display: flex;
          margin-right: 1rem;
          &.link {
            cursor: pointer;
            text-decoration: underline dotted;
          }
        }
      }
      .leader-line,
      .contact-line {
        font-size: 0.85rem;
      }
    }
  }

  .gutters {
    padding: 8px 16px;
  }
}

.modal {
  font-size: 14px;

  input {
    height: 30px;
    font-size: 14px;
  }
}

.detail-modal {
  .detail-info {
    display: flex;
    padding: 3px 0;
  }

  .detail-key {
    width: 30%;
    padding-left: 20px;
  }

  .detail-value {
    width: 70%;
  }
}

.file-delete-modal {
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .modal_button {
      color: #fff;
      width: 160px;
      margin: 8px;
    }

    .delete_button {
      background-color: #dc004e;
      &:hover {
        background-color: #9a0036;
      }
    }
  }
}

.error {
  color: #c70000;
  font-size: 12px;
}

.alert p {
  font-size: 12px;
}

.loading-spinner {
  text-align: center;
  padding: 100px 0;
  img {
    width: 60px;
  }
}

.share-modal {
  // .MuiDialogContent-root {
  // min-height: 150px;
  // height: 25vh;
  // }

  .toggle-button-group {
    padding-bottom: 15px;
    button {
      height: 36px;
      width: 80px;
    }
  }
}
