.account-avatar {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d0d3fa;
  .avatar {
    height: 85px;
    width: 85px;
    border-radius: 50%;
    border: 1px solid white;
  }
}
