.maneuver {
  background-size: 19px 630px;
  left: 0;
  width: 16px;
  height: 16px;
  margin-right: 1rem;
  background-position: 0 16px;
}

.maneuver.ferry {
  background-position: 0 -614px;
}

.maneuver.ferry-train {
  background-position: 0 -566px;
}

.maneuver.merge {
  background-position: 0 -143px;
}

.maneuver.straight {
  background-position: 0 -534px;
}

.maneuver.fork-left {
  background-position: 0 -550px;
}

.maneuver.ramp-left {
  background-position: 0 -598px;
}

.maneuver.roundabout-left {
  background-position: 0 -197px;
}

.maneuver.turn-left {
  background-position: 0 -413px;
}

.maneuver.turn-sharp-left {
  background-position: 0 0;
}

.maneuver.turn-slight-left {
  background-position: 0 -378px;
}

.maneuver.uturn-left {
  background-position: 0 -305px;
}

.maneuver.fork-right {
  background-position: 0 -499px;
}

.maneuver.ramp-right {
  background-position: 0 -429px;
}

.maneuver.roundabout-right {
  background-position: 0 -232px;
}

.maneuver.turn-right {
  background-position: 0 -483px;
}

.maneuver.turn-sharp-right {
  background-position: 0 -582px;
}

.maneuver.turn-slight-right {
  background-position: 0 -51px;
}

.maneuver.uturn-right {
  background-position: 0 -35px;
}

.maneuver.no-image {
  background-image: none;
}

.maneuver {
  background-image: url(http://maps.gstatic.com/mapfiles/api-3/images/maneuvers.png);
}
