.sidebar {
  background-color: $primary;
  display: flex;
  z-index: 300;
  .navigator {
    position: relative;
    background-color: $primary;
    list-style: none;
    width: 120px;
    padding: 26px 0px 0px 13px;
    flex: none;
    li {
      div.nav-item {
        display: flex;
        font-size: 14px;
        flex-direction: column;
        width: 107px;
        height: 80px;
        border-radius: 6px 0px 0px 6px;
        margin-bottom: 10px;
        padding: 5px 15px 5px 5px;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        svg {
          fill: $dark;
          font-size: 62px;
          width: 62px;
          height: 62px;
        }
        .description {
          margin-top: -6px;
          text-align: center;
          color: #747474;
        }
      }

      div.active {
        background-color: #dce1ec;
        img {
          color: #8da3c5;
        }
        svg {
          fill: #8da3c5;
        }
      }
      div.navigator-description {
        display: none;
      }

      &:hover {
        div.navigator-description {
          position: absolute;
          top: 0px;
          left: 120px;
          background-color: $light;
          width: 300px;
          display: block;
          padding-top: 25px;
          li {
            font-size: 25px;
            font-weight: 500;
            height: 60px;
            white-space: nowrap;
            padding: 10px 0px 0px 20px;
            &.matched {
              color: white;
            }
          }
          z-index: 100;
        }
      }

      &:active,
      &:focus,
      &:focus-within {
        div.navigator-description {
          display: none;
        }
      }
    }
    // li:nth-child(3) {
    //   div.nav-item {
    //     .description {
    //       margin-top: 5px;
    //     }
    //   }
    // }
  }
}

.sub-menu {
  z-index: 10;
  // width: 300px;
  min-width: 210px;
  background-color: $light;
  flex: auto;
  display: flex;
  flex-direction: column;
  .item-list {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btn {
      width: 217px;
      height: 52px;
      background-color: $green;
      border-radius: 6px;
      border: none;
      color: white;
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      user-select: none;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
      &:active {
        background-color: green;
      }
    }

    ul {
      list-style: none;
      margin: 40px 0px 40px 0px;
      li {
        color: #8a88a0;
        margin: 20px 0 20px 30px;
        cursor: pointer;

        svg {
          fill: #8a88a0;
        }
        .row {
          font-size: 14px;
          .label {
            padding-left: 20px;
          }
        }

        a {
          color: #2e2a60;
          text-decoration: none;
        }

        &.active {
          font-weight: bold;
          color: #232231;
          svg {
            fill: #232231;
          }
          a {
            color: #232231;
          }
        }

        .group-filter {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border-width: 2px;
          border: solid;
        }
      }
    }
  }
  .sub-menu-divider {
    color: #cdd4e3;
  }
  .count {
    border-radius: 50%;
    color: white;
    font-size: 12px;
    width: 21px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
