.contacts {
  background-color: white;
  padding: 10px;
  height: 100%;
}

.contact-modal-section {
  width: 100%;
  padding: 10px 0;
  .section-title {
    padding-bottom: 10px;
    font-weight: 500;
  }
}

.contact-section {
  .create-new-button {
    button {
      margin: 20px 0 0;
      height: 55px;
    }

    .button-group {
      margin-right: 40px;
    }
  }

  .contact-search-bar {
    border-radius: 5px;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2),
      0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
    width: 60%;
  }

  .search-input-field {
    width: 92%;
  }

  .contact-container {
    margin-left: -20px;
    margin-right: -20px;
  }

  .custom-card_header {
    display: flex;
    padding: 16px;
    // height: 85px;
    position: relative;
    button {
      padding-left: 0;
    }
    .avatar {
      flex: 0 0 auto;
      width: 50px;
      padding: 10px 0;
    }
    .contact-avatar {
      margin-right: 10px;
      margin-top: 10px;
      width: 60px;
      height: 60px;
    }
    .primary-title {
      display: flex;
      width: 100%;
      font-size: 15px;
      font-weight: 400;
      font-family: Roboto, Helvetica Neue, sans-serif;
      line-height: 1.43;
      padding: 10px 0;
      color: rgba(0, 0, 0, 0.64);
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;

      .header-role {
        font-weight: 700;
        color: #5f5f5f;
        font-size: 13px;
      }
      .primary-header {
        color: #5f5f5f;
        font-size: 16px;
        font-weight: normal;
        text-decoration: none;
        width: auto;
      }
      .header-address {
        color: #5f5f5f;
        font-size: 13px;
        font-weight: 400;
      }
    }
    .select-checkbox {
      position: absolute;
      right: 0;
      top: 0;
      span {
        color: $gray-blue;
      }
    }
    .contact-actions {
      width: auto;
      display: inline-flex;
      .contact-role {
        width: 83px;
        margin-top: 15px;
        .content_value {
          text-align: left;
        }
      }
      .online-status-button {
        font-size: 2rem;
        .online-icon {
          .MuiSvgIcon-root {
            color: $sushi-color;
          }
        }
        .offline-icon {
          .MuiSvgIcon-root {
            color: $gray-blue;
          }
        }
      }
    }
  }

  .text-container {
    .content_list {
      font-size: 13px;
      .content_key {
        font-weight: 700;
        line-height: 8px;
        // color: $gray-blue;
      }
      .content_value {
        color: $sushi-color;
      }
    }
  }

  .contact-card {
    padding: 20px;
    .MuiCard-root {
      height: 100%;
    }
    .contact-email-list {
      min-width: 150px;
      .MuiInput-underline:before {
        border-bottom: none;
      }
      .MuiInput-underline:after {
        border-bottom: none;
      }
      .MuiSelect-selectMenu {
        font-size: 12px;
        color: $sushi-color;
      }
      .MuiSelect-icon {
        color: $sushi-color;
      }
    }
    .MuiCardHeader-action {
      margin-top: 20px;
      margin-right: 10px;
    }
  }

  .contact-card-action {
    border-bottom: 1px solid $gray-blue;
    margin: 0px 16px 8px 16px;
    flex-wrap: wrap;
    gap: 4px;
    padding-bottom: 20px;

    .contact-card-action-icon {
      width: 36px;
      height: 36px;
      margin: auto;
      background-color: #fff;
      color: $sushi-color;
      fill: #fff;
      border: 1px solid $sushi-color;
    }
  }

  .contact-card_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    .content_list {
      padding: 0 3px;
      p {
        font-size: 12px;
      }

      .content_key {
        font-weight: 700;
        color: $gray-blue;
      }

      .content_value {
        color: #5f5f5f;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  // .contact-card-action-button {
  //   padding: 16px 16px 24px 16px;
  //   button {
  //     margin: auto;
  //   }
  // }

  .contact-table {
    width: 100%;
    padding: 20px;
    padding-left: 0;

    .contact-member-name {
      display: flex;
      align-items: center;
      padding: 6px;

      p {
        margin-left: 6px;
        padding: 10px 0;
      }

      .online-text-color {
        color: $sushi-color;
      }
    }

    tr.Mui-selected {
      background: #e5f4d8 !important;
    }
  }

  .MuiCardHeader-avatar {
    margin-right: 10px;
  }

  .MuiAvatar-root {
    border: 1px solid $sushi-color;
  }
}

.contact-cards-pagination {
  // padding: 20px 0;
  display: flex;
  padding: 20px;
  margin-left: calc(100% - 50vw);
  .item-per-page {
    display: flex;
    .MuiFormControl-root {
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none !important;
      }
      .MuiInput-underline:before {
        border-bottom: none !important;
      }
      .MuiInput-underline:after {
        border-bottom: none !important;
      }
    }

    .item-count-text {
      padding: 5px 0;
      margin-left: 10px;
    }
  }
  .MuiPagination-root {
    margin-left: 200px;
  }
}

.clickable {
  cursor: pointer;
  color: $sushi-color !important;
  text-decoration: underline;
}
