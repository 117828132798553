.filmstrip__toolbar {
  @include flex();
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  position: relative;
  width: $filmstripToggleButtonWidth;

  button {
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    background: transparent;
    opacity: 0.7;
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    -webkit-appearance: none;

    &:hover {
      opacity: 1;
    }

    i {
      cursor: pointer;
    }
  }
}

.vertical-filmstrip {
  .hide-videos {
    .filmstrip__toolbar {
      #toggleFilmstripButton {
        .jitsi-icon {
          transform: rotateX(180deg);
        }
      }
    }
  }
}

.remotevideomenu {
  display: none;
  justify-content: space-around;
  width: 100%;
  top: 0 !important;
  bottom: 0;
  height: 100%;
  background-color: rgba(45, 45, 45, 0.8);
  align-items: center;
  flex-wrap: wrap;
  button {
    background-color: rgba(45, 45, 45, 0.2) !important;
    padding: 0 !important;
    border: none;
    outline: 0;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    &:hover {
      background-color: $primary !important;
      cursor: pointer;
      svg {
        fill: $white;
      }
    }
    svg {
      fill: $white;
      height: 20px !important;
      width: 20px !important;
    }
  }

  .btn {
    background-color: transparent !important;
    padding: 0 !important;
    border: none;
    outline: 0;
  }
}

.videocontainer {
  &:hover {
    .remotevideomenu {
      display: flex;
    }
  }
}
