/**
 * CSS styles that are specific to the filmstrip that shows the thumbnail tiles.
 */
.tile-view {
  /**
     * Add a border around the active speaker to make the thumbnail easier to
     * see.
     */
  .largeVvideo {
    display: none;
  }
  #filmstripRemoteVideosContainer {
    .videocontainer {
      width: calc(calc(28vh - 50px) * 1.77);
      height: calc(28vh - 50px);
    }
  }
  &[data-paticipants='1'] {
    #filmstripRemoteVideosContainer {
      .videocontainer {
        width: calc(calc(80vh - 50px) * 1.77);
        height: calc(80vh - 50px);
      }
    }
  }
  &[data-paticipants='2'] {
    #filmstripRemoteVideosContainer {
      .videocontainer {
        width: calc(40vw - 50px);
        height: calc(50vh - 50px);
      }
    }
  }
  &[data-paticipants='3'],
  &[data-paticipants='4'] {
    #filmstripRemoteVideosContainer {
      .videocontainer {
        width: calc(40vw - 50px);
        height: calc(40vh - 50px);
      }
    }
  }
  &[data-paticipants='5'],
  &[data-paticipants='6'] {
    #filmstripRemoteVideosContainer {
      .videocontainer {
        width: calc(30vw - 50px);
        height: calc(40vh - 50px);
      }
    }
  }
  .active-speaker {
    box-shadow: 0 0 5px 3px $videoThumbnailSelected;
  }

  #filmstripRemoteVideos {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    width: 100vw;
    margin: 100px 0px;
  }

  .filmstrip__videos .videocontainer {
    &:not(.active-speaker),
    &:hover:not(.active-speaker) {
      border: none;
      box-shadow: none;
    }
  }

  #remoteVideos {
    /**
         * Height is modified with an inline style in horizontal filmstrip mode
         * so !important is used to override that.
         */
    height: 100% !important;
    width: 100%;
  }

  .filmstrip {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    // position: fixed;
    top: 0;
    width: 100%;
    z-index: $filmstripVideosZ;

    &.shift-right {
      margin-left: $sidebarWidth;
      width: calc(100% - #{$sidebarWidth});

      #filmstripRemoteVideos {
        width: calc(100vw - #{$sidebarWidth});
      }
    }
  }

  /**
     * Regardless of the user setting, do not let the filmstrip be in a hidden
     * state.
     */
  .filmstrip__videos.hidden {
    display: block;
  }

  #filmstripRemoteVideos {
    box-sizing: border-box;

    /**
         * Allow vertical scrolling of the thumbnails.
         */
    overflow-x: hidden;
    overflow-y: auto;
  }

  /**
     * The size of the thumbnails should be set with javascript, based on
     * desired column count and window width. The rows are created using flex
     * and allowing the thumbnails to wrap.
     */
  #filmstripRemoteVideosContainer {
    align-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    .videocontainer__background {
      background-color: transparent;
    }
    .videocontainer {
      border: 0;
      box-sizing: border-box;
      display: block;
      margin: 5px;
    }

    video {
      object-fit: cover;
    }
  }

  .has-overflow#filmstripRemoteVideosContainer {
    align-content: baseline;
  }

  .has-overflow .videocontainer {
    align-self: baseline;
  }

  /**
     * Firefox flex acts a little differently. To make sure the bottom row of
     * thumbnails is not overlapped by the horizontal toolbar, margin is added
     * to the local thumbnail to keep it from the bottom of the screen. It is
     * assumed the local thumbnail will always be on the bottom row.
     */
  .has-overflow #localVideoContainer {
    margin-bottom: 100px !important;
  }
}
