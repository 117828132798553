.container-navigation-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .button-group {
    display: flex;
    button {
      display: flex;
      border: none;
      padding: 6px 11px;
      margin-right: 5px;
      align-items: center;
      font-size: 0.875rem;
      border-radius: 7px;
      cursor: pointer;
      .btn-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
        width: 25px;
      }
    }
    .btn-groups {
      min-width: 145px;
    }
    .btn-group-memberships {
      min-width: 215px;
    }
    .Mui-disabled {
      border: none !important;
    }
  }
}
