.phone-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .phone-list {
    width: 100%;
    .list-header {
      font-size: 1rem;
    }

    .item {
      width: 100%;
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      align-content: center;

      .item-text {
        width: 100%;
        display: grid;
        grid-template-columns: 20% 15% 45% 15%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-content: center;
        align-items: baseline;

        &.email {
          grid-template-columns: 20% 60% 15%;
        }

        .description,
        .phone-type-selector,
        .method-type-selector {
          width: 100%;
          margin: 0 0.5rem;
        }
        .phone-with-btns {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          .phone-input {
            width: 100%;
            margin: 0 0.5rem;

            .special-label {
              color: rgba(0, 0, 0, 0.54);
              font-size: 0.75rem;
              top: -0.95rem;
            }
            .phone-input-field {
              font-size: 1rem;
              border-radius: 0;
              padding: 0 0 0 3.5rem;
              border: none;
              border-bottom: 1px solid #ddd;
              width: 13rem;
              background: none;
              &:active {
                border: none;
                border-bottom: 1px solid #ddd;
              }
              &:disabled {
                color: rgba(0, 0, 0, 0.54);
                background: none;
              }
            }
          }
        }
      }
    }
  }
}

.location-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .list-header {
    font-size: 1rem;
    padding-left: 16px;
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    line-height: 48px;
  }
  .location-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;

    .item {
      flex: 1 1 40%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .item-header {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
      }

      .item-text {
        width: 100%;
      }
    }
    .item-company {
      padding: 0;
    }
  }
}

.achievement-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .achievement-list {
    width: 100%;
    .list-header {
      font-size: 1rem;
    }
    .item {
      width: 100%;
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      align-content: center;
      .item-text {
        width: 100%;
        display: grid;
        grid-template-columns: 30% 65%;
        justify-content: space-evenly;
        align-content: center;
        align-items: baseline;
      }
    }
  }
}
