.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &_form {
    width: 500px;
    margin-bottom: 100px;

    label,
    span,
    input {
      font-size: 14px;
    }

    .login_form-logo {
      text-align: center;
      margin-bottom: 30px;

      img {
        width: 200px;
      }
    }

    .login_form-row {
      margin-top: 20px;
    }

    .login_form-footer {
      margin-top: 30px;
    }

    .MuiFormControl-root,
    .MuiButtonBase-root {
      width: 100%;
    }

    .MuiButton-label {
      color: #ffffff;
    }
  }

  .label-error {
    font-size: 16px;
    margin-top: 10px;
    color: red;
  }
}

.emailInput {
  margin-top: 50px;
}