.midContainer {
  padding: 0 0 100px 20px;
}
.createdBy {
  span {
    font-size: 18px;
    margin-top: 15px;
    display: inline-block;
    padding-right: 5px;
  }
}

h3 {
  font-size: 18px;
  display: inline-block;
  padding-right: 5px;
}

.mt20 {
  margin-top: 20px !important;
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
}

.roleGroup {
  display: inline-flex;
  width: 30%;
}

.assignRoleBlock {
  border-top: 1px solid #bbb;
  h5 {
    font-size: 16px;
  }
  .topRow {
    display: flex;
    justify-content: space-between;
    button {
      padding: 4px 10px;
    }
  }
  .rolesGrid {
    p {
      font-size: 12px;
      color: #888;
    }
  }
}
.project-avatar {
  display: none !important;
}

.EditIcon {
  position: absolute;
  margin-top: 2px;
  margin-left: 8px;
  color: #94c03d;
  height: 17px !important;
  width: 17px !important;
  cursor: pointer;
}

.hourlyRateHeading {
  margin-top: 30px;
  color: #888;
  text-align: center;
}
.gridContainer {
  display: flex;
  justify-content: space-around;
  .gridContainer1 {
    margin: 10px auto auto 10px;
    .moneyIcon {
      color: #9baecc;
      height: 20px;
      width: 20px;
      position: absolute;
      margin-top: 10px;
      margin-left: 7px;
    }
    .textField {
      padding-left: 18px;
      height: 38px;
      width: 130%;
    }
  }
  .gridContainer2 {
    margin-top: 10px;

    margin-right: 15px !important;
  }
  .gridContainer3 {
    margin-top: 10px;
    margin-right: 15px !important;
  }
}
.hourlyP {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  margin-left: 24px;
}
