.filmstrip__videos .videocontainer {
  display: inline-block;
  position: relative;
  background-size: contain;
  border: $thumbnailVideoBorder solid transparent;
  border-radius: $borderRadius;
  margin: 0 $thumbnailVideoMargin;

  &.videoContainerFocused,
  &:hover {
    cursor: pointer;
  }

  /**
     * Focused video thumbnail.
     */
  &.videoContainerFocused {
    border: $thumbnailVideoBorder solid $videoThumbnailSelected;
    box-shadow: inset 0 0 3px $videoThumbnailSelected,
      0 0 3px $videoThumbnailSelected;
  }

  .remotevideomenu > .icon-menu {
    display: none;
  }

  /**
     * Hovered video thumbnail.
     */
  &:hover:not(.videoContainerFocused):not(.active-speaker) {
    cursor: pointer;
    border: $thumbnailVideoBorder solid $videoThumbnailHovered;
    box-shadow: inset 0 0 3px $videoThumbnailHovered,
      0 0 3px $videoThumbnailHovered;

    .remotevideomenu > .icon-menu {
      display: inline-block;
    }
  }

  & > video {
    cursor: pointer;
    border-radius: $borderRadius;
    object-fit: cover;
    overflow: hidden;
  }

  .presence-label {
    position: absolute;
    z-index: $zindex3;
  }
}

.remote-user-actions,
.local-user-actions {
  padding: 0;
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding: 6px 8px;
      display: block;
      color: #888888;
      text-decoration: none;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;
      .amera-icon {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          width: 24px;
          fill: #8ea4c6;
        }
      }
      &:hover {
        background-color: $primary;
        color: $white;
        svg {
          fill: $white;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

img {
  &.white {
    filter: invert(1);
  }
}
