.group-section {
  .show-grid.row {
    position: relative;
    .card-pagination {
      position: absolute;
      bottom: 10px;
      right: 57px;
    }
  }

  .card-list-view {
    padding-bottom: 40px;
  }

  .list-view-section {
    font-size: 14px;
    padding: 20px 0;

    .search-label {
      input {
        font-size: 14px;
      }
    }

    tr.Mui-selected {
      background: #e5f4d8 !important;
    }

    .group-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #f8faff;
      overflow: hidden;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .row {
    margin: 0 !important;
  }
  .group-card {
    padding: 15px 45px;
    .card {
      height: 120px;
      cursor: pointer;
      .group-image {
        position: absolute;
        top: 10px;
        left: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #f8faff;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .card-body {
        .card-title {
          margin-left: 50px;
          font-size: 14px;
        }
        .card-subtitle {
          margin-left: 50px;
          font-size: 12px;
        }
        .card-text {
          margin-top: 20px;
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.multi-filter-option {
  width: 100%;
  padding: 20px;
  padding-left: 0;

  .filter-select-item {
    padding: 5px;
    width: 160px;
    margin: auto;
    .MuiFormControl-root {
      width: 100%;
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      height: 45px;

      label {
        transform: translate(12px, 14px) scale(1);
      }

      label.MuiInputLabel-shrink {
        transform: translate(12px, -7px) scale(0.75);
        background-color: #fff;
      }

      .MuiFilledInput-input {
        padding: 14px 12px 10px;
        // width: 70%;
      }

      .MuiFilledInput-underline:before {
        border: none;
      }

      .MuiFilledInput-underline:hover:before {
        border: none;
      }

      .MuiFilledInput-underline:hover:focus {
        border: none;
      }

      .MuiFilledInput-underline:after {
        border: none;
      }

      .MuiFilledInput-root {
        background-color: initial;
        height: 100%;
        &:hover {
          background-color: initial;
          border: none;
        }
        .MuiSelect-filled.MuiSelect-filled:focus {
          background-color: initial;
        }
      }
    }
  }
}

.group-section {
  .create-new-button {
    button {
      margin: 20px 0 0;
      height: 55px;
    }

    .button-group {
      margin-right: 40px;
    }
  }

  .multi-filter-option {
    width: 100%;
    padding: 20px;
    padding-left: 0;

    .filter-select-item {
      padding: 5px;
      width: 160px;
      margin: auto;
      .MuiFormControl-root {
        width: 100%;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        height: 45px;

        label {
          transform: translate(12px, 14px) scale(1);
        }

        label.MuiInputLabel-shrink {
          transform: translate(12px, -7px) scale(0.75);
          background-color: #fff;
        }

        .MuiFilledInput-input {
          padding: 14px 12px 10px;
          // width: 70%;
        }

        .MuiFilledInput-underline:before {
          border: none;
        }

        .MuiFilledInput-underline:hover:before {
          border: none;
        }

        .MuiFilledInput-underline:hover:focus {
          border: none;
        }

        .MuiFilledInput-underline:after {
          border: none;
        }

        .MuiFilledInput-root {
          background-color: initial;
          height: 100%;
          &:hover {
            background-color: initial;
            border: none;
          }
          .MuiSelect-filled.MuiSelect-filled:focus {
            background-color: initial;
          }
        }
      }
    }
  }

  .contact-search-bar {
    border-radius: 5px;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.2),
      0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12);
    width: 60%;
  }

  .search-input-field {
    width: 92%;
  }

  .group-container {
    margin-left: -20px;
    margin-right: -20px;
  }

  // .custom-card_header {
  //   // display: flex;
  //   padding: 16px;
  //   height: 140px;
  //   position: relative;

  //   .primary-title {
  //     flex: 1 1 auto;
  //     width: calc(100% - 125px);
  //     font-size: 0.875rem;
  //     font-family: Roboto,Helvetica Neue,sans-serif;
  //     font-weight: 400;
  //     line-height: 1.43;
  //     font-size: 18px;
  //     font-weight: 600;
  //     // padding: 20px 0;
  //     .primary-header {
  //       color: rgba(0, 0, 0, 0.54);
  //     }
  //     .second-header {
  //       color: rgba(0, 0, 0, 0.54);
  //     }
  //   }

  //   .secondary-title {
  //     padding: 5px 0;
  //     font-weight: 500;
  //   }

  //   .select-checkbox {
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //   }

  //   .bar-value {
  //     position: absolute;
  //     top: 15px;
  //     right: 45px;
  //     font-size: 10px;
  //     font-weight: 600;
  //   }

  //   .bar-title {
  //     position: absolute;
  //     top: 35px;
  //     right: 52px;
  //     font-size: 12px;
  //     font-weight: 500;
  //   }
  // }

  .action-button-group {
    .icon-button {
      // display: flex;
      margin: auto;
      text-align: center;
    }
    p.icon-button-label {
      padding: 15px 0;
      font-size: 12px;
    }
  }

  .text-container {
    .content_list {
      font-size: 12px;
      .content_key {
        color: #8ea4c6;
      }
      .content_value {
        color: #94c03d;
      }
    }
  }

  .group-card {
    padding: 20px;
    .MuiCard-root {
      height: 100%;
    }

    .MuiCardHeader-action {
      margin-top: 20px;
      margin-right: 10px;
    }
  }

  .group-card-action {
    border-bottom: 1px solid #8ea4c6;
    width: 90%;
    margin: auto;
    padding: 16px 0 !important;

    .group-card-action-icon {
      width: 36px;
      height: 36px;
      margin: auto;
      background-color: #8ea4c6;
      color: #fff;
    }
  }

  .group-card-action-button {
    padding: 16px;
    button {
      margin: auto;
    }
  }

  .group-card_content {
    .content_list {
      padding: 10px 0;
      p {
        font-size: 12px;
      }

      .content_key {
        color: #8ea4c6;
      }

      .content_value {
        color: #94c03d;
      }
    }
  }

  .MuiCardHeader-avatar {
    margin-right: 10px;
  }

  .MuiAvatar-root {
    border: 1px solid #94c03d;
  }
}

.group-detail-card {
  padding: 10px 0;
  form {
    width: 100%;
  }
}

.group-member-card {
  #member-card {
    ul {
      width: 100%;
      .MuiIconButton-edgeEnd {
        color: #dc004e;
      }
    }
  }
}

.new-group-form {
  padding: 20px 0 0;

  .new-group-name-container {
    padding: 16px;
  }

  .grid-container {
    padding: 15px;
    min-height: 523px;
  }

  .form-title {
    padding: 15px;
    font-weight: 600;
    color: #000;
  }

  .upload-picture-section {
    max-width: 350px;
    margin: auto;
    .input-file {
      display: none;
    }

    .upload-card-content {
      // text-align: center;
      border: 2px solid #e4e4e4;
      border-radius: 10px;
      width: 100%;
      height: 402px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        border: 2px solid #94c03d;
      }
    }

    .group-picture {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .option-box {
    padding: 15px;
    .MuiFormControl-root {
      padding: 0 0 0 30px;
    }
  }

  .pin-section {
    padding: 15px;
    border: 2px solid #e4e4e4;
    display: flex;
    .pin-input-container,
    .create-key-container {
      width: 50%;
      .pin_input {
        width: 100%;
      }
      .create-key-btn {
        float: right;
        margin-top: 85px;
        margin-right: 50px;
      }
    }
  }

  .text {
    color: #94c03d;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
  }
}

.new-group-form {
  margin-top: 8px;
  .exchange-option {
    margin: 20px 0;
  }
}

.new-group-button {
  .new-group-action-button {
    min-width: 120px;
    text-overflow: clip;
    white-space: nowrap;
    margin: 8px;
  }
}

.contact-table {
  width: 100%;
  padding: 20px;
  padding-left: 0;

  .contact-member-name {
    display: flex;
    padding: 6px;

    p {
      margin-left: 6px;
      padding: 10px 0;
    }
  }

  tr.Mui-selected {
    background: #e5f4d8 !important;
  }
}
