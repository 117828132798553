:root {
  --rowNum: 4;
  --colNum: 4;
  --gridHeight: calc(
    100vh / var(--rowNum) - 5px * (var(--rowNum) - 1) / var(--rowNum)
  );
}
video#large_videowrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  object-fit: cover;
  height: 100%;
}
video#large_videowrapper {
  width: 100%;
}
// video#localVideo1 {
//   width: 213px;
// }
// #filmstripRemoteVideosContainer:not(.wrapperV) video {
//   width: 213px;
// }

// .grid-view {
//   position: relative !important;
// }
// .grid-container {
//   display: grid;
//   grid-template-columns: auto auto auto;
//   background-color: #2196f3;
//   padding: 10px;
// }
// .grid-container > .grid-item {
//   //background-color: rgba(255, 255, 255, 0.8);
//   //border: 1px solid rgba(0, 0, 0, 0.8);
//   padding: 20px;
//   font-size: 30px;
//   text-align: center;
// }
#localVideoContainer2 {
  display: none;
}
.wrapperV {
  height: 100%;
  margin: 50px 25px;
  padding: 5px 100px;
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: 100px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
}
.wrapperV > .grid-item {
  border: 1px solid #333;
  background-color: var(--planner-default-bg-color);
  position: relative;
  margin: 50px;
  height: 100px;
  width: 200px;
}
@for $i from 0 to 100 {
  .wrapperV > .grid-item:nth-child(#{$i}) {
    background-color: rgba(
      random(256)-1,
      random(256)-1,
      random(256)-1,
      random(10) / 10
    );
  }
}
.container-dialog {
  z-index: 9;
  position: absolute;
  margin: 50px 0;
  width: 100%;
}
.container-dialog > .ui-dialog-container > .ui-dialog {
  transform: translate(0px, 0px) !important;
  margin: auto;
}
.video-container {
  position: relative;
  #videoElement {
    width: 100%;
  }
}

.jqi {
  background-color: $white;
  padding: 10px 25px;
  .jqiclose {
    text-align: right;
    font-size: 24px;
    font-weight: 700;
    color: #8e8e8e;
    cursor: pointer;
    &:hover {
      color: #94c03d;
    }
  }
}
.aui-blanket {
  transition: opacity 0.2s, visibility 0.2s;
  transition-delay: 0.1s;
  visibility: visible;
}
.jqi.dialog.aui-layer.aui-dialog2.aui-dialog2-small {
  box-sizing: border-box;
  height: auto;
  min-height: 131px;
  overflow: visible;
  visibility: visible;
  width: 400px;
}
h2.aui-dialog2-header-main {
  font-size: 1.6666666667em;
  font-weight: 400;
  color: #eceef1;
}
.dialog .aui-dialog2-header-main {
  display: table-cell;
  padding-right: 0;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.dialog .aui-dialog2-content {
  box-sizing: border-box;
  color: #333333;
  font-size: 1.1666666667em;
  overflow: auto;
  max-height: 100%;
  margin-bottom: 20px;
  input {
    padding: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #e5e5e5;
    margin: 5px 0 0;
  }
}
.dialog .aui-dialog2-footer {
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  height: 51px;
  overflow: hidden;
  width: 100%;
}
.dialog .aui-dialog2-footer,
.dialog .aui-dialog2-header {
  border: none;
  h2 {
    font-size: 22px;
    margin-top: 10px;
    color: #94c03d;
  }
  button.button-control {
    background: #8e8e8e;
    border: none;
    padding: 10px 15px;
    height: 40px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    &.button-control_primary {
      background-color: #94c03d;
    }
    & + button {
      margin-left: 10px;
    }
  }
}
#sharedVideoIFrame {
  width: calc(100% - 150px);
  height: calc(100% - 100px);
  opacity: 1;
}

.video-call {
  &:hover {
    .new-toolbox {
      bottom: 0;
    }
  }
}

#videoResolutionLabel {
  z-index: 3 + 1;
}

select {
  max-width: 100%;
  option {
    max-width: 100%;
    width: 100%;
  }
}

.shortcuts-list {
  list-style-type: none;
  padding: 0;

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .item-action {
    background-color: #94c03d;
    color: $white;
    width: 20px;
    color: #ffffff;
    text-align: center;
  }
}
/**
* Initialize
**/

.popupmenu {
  min-width: 75px;
  text-align: left;
  padding: 0px;
  width: 180px;
  white-space: nowrap;

  &__item {
    list-style-type: none;
    height: 35px;
  }

  // Link Appearance
  &__link,
  &__contents {
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    height: 100%;
    font-size: 9pt;
    width: 100%;
    cursor: pointer;
    padding: 0 5px;
    color: green;

    &:hover {
      background-color: black;
      color: red;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
  }

  &__link {
    i {
      cursor: pointer;
    }
  }

  &__contents {
    display: flex;

    /**
         * Positioning styles on the slider and its container are used to make
         * the container fit the popup width, by removing the slider from the
         * page flow, and then making the slider fit the container.
         */
    .popupmenu__slider_container {
      position: relative;
      width: 100%;

      .popupmenu__slider {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;

        // &::-webkit-slider-runnable-track {
        //    // background-color: $popupSliderColor;
        // }

        // &::-moz-range-track {
        //     background-color: $popupSliderColor;
        // }

        // &::-ms-fill-lower {
        //     background-color: $popupSliderColor;
        // }
      }
    }
  }

  &__icon {
    vertical-align: middle;
    position: relative;
    display: inline-block;
    min-width: 20px;
    height: 100%;
    padding-right: 10px;

    // > * {
    //     @include absoluteAligning();
    // }
  }

  .icon-kick,
  .icon-play,
  .icon-stop {
    font-size: 8pt;
  }
}

/**
 * Override reset css styling modifying all lists and set negative margin to
 * reduce the visibility of padding on AtlasKit
 * InlineDialogs.
 */
ul.popupmenu {
  margin: -16px -24px;
}

span.remotevideomenu:hover ul.popupmenu,
ul.popupmenu:hover {
  display: block !important;
}
