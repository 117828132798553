.chat-section {
  width: 100%;
  height: calc(100vh - 214px);
  display: flex;
  flex-direction: row;
  position: relative;

  .file-share-progress {
    position: absolute;
    top: 20px;
    min-width: 300px;
    z-index: 1;
  }

  .messages-container {
    width: 100%;
    min-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center; //temp TODO: design update required
    z-index: 1;

    .chat-messages {
      width: 100%; //temp needs to update
      height: calc(100vh - 174px - 50px);
      margin: 10px 0 10px 0px;
      display: flex;
      flex-direction: column-reverse;

      &::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $dark;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(220, 220, 220);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        border-radius: 10px;
      }

      .chat-message {
        &.my-message {
          float: right;
          flex-direction: row-reverse;
          .chat-message-avatar-div {
            display: flex;
            justify-content: flex-end;
            min-width: 56px;
            margin-bottom: auto;
            .chat-message-avatar {
              border-radius: 50%;
            }
          }
          .message-content {
            flex-direction: row;
            max-width: 70%;
            .message-time {
              text-align: right;
              color: #182a63;
              font-size: 12px;
            }
            .message-files {
              display: block;
              min-height: 1px;
              max-width: 328px;
              overflow: auto;
              .ReactGridGallery_tile-icon-bar {
                display: none;
              }
              .ReactGridGallery_tile-bottom-bar {
                display: flex;
                justify-content: flex-end;
              }
            }
            .message-text-wrapper {
              max-width: 100%;
              .message-text {
                float: right;
                color: #777777;
                background-color: #cedefd;
                letter-spacing: 0.38px;
                font-family: Roboto, Helvetica Neue, sans-serif; //not using from mockup need to update
                font-weight: 500;
                padding: 15px 20px 20px 15px;
                border-radius: 20px 0px 20px 20px;
                margin-top: 4px;
                margin-left: auto;
                word-break: break-all;
                .message-files-info {
                  list-style-position: inside;
                }
              }
            }
          }
        }

        &.other-message {
          float: left;
          flex-direction: row;
          .chat-message-avatar-div {
            display: flex;
            justify-content: flex-start;
            min-width: 50px;
            margin-bottom: auto;
            .chat-message-avatar {
              border-radius: 50%;
            }
          }
          .message-content {
            display: flex;
            flex-direction: column;
            max-width: 70%;
            .message-time {
              text-align: left;
              color: #182a63;
              font-size: 12px;
            }
            .message-files {
              display: block;
              min-height: 1px;
              max-width: 328px;
              overflow: auto;
              .ReactGridGallery_tile-icon-bar {
                display: none;
              }
              .ReactGridGallery_tile-bottom-bar {
                display: flex;
                justify-content: flex-end;
              }
            }
            .message-text-wrapper {
              max-width: 100%;
              .message-text {
                float: left;
                color: #6855f7;
                background-color: #cedefd;
                letter-spacing: 0.38px;
                font-family: Roboto, Helvetica Neue, sans-serif; //not using from mockup need to update
                font-weight: 500;
                padding: 15px 20px 20px 15px;
                border-radius: 0px 20px 20px 20px;
                margin-top: 4px;
                word-break: break-all;
                .message-files-info {
                  list-style-position: inside;
                }
              }
            }
          }
        }

        &.skeleton {
          .chat-message-avatar-div {
            min-width: auto;
            margin-right: 10px;
          }
          .message-content {
            min-width: 50%;
            .message-time {
              width: 80px;
            }
            .message-text {
              height: 50px;
            }
          }
        }

        .grid-gallery-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .images-circular-progress {
            z-index: 2;
            position: absolute;
            left: calc(50% - 20px);
          }
          .ReactGridGallery {
            z-index: 1;
            .ReactGridGallery_tile-viewport {
              width: 160px !important;
              img {
                margin-left: 0 !important;
              }
            }
          }
        }
        .message-text-file {
          display: inline-flex;
          min-width: 51%;
          .file-progress-icon {
            min-width: 40px;
            margin-left: 10px;
            margin-right: -20px;

            .MuiCircularProgress-root,
            .MuiSvgIcon-root {
              width: 20px !important;
              height: 20px !important;
            }
            .MuiSvgIcon-root {
              color: #c70000;
            }
          }
        }
      }

      #no-coversation-layout {
        display: flex;
        justify-content: center;
        margin-bottom: auto;
        margin-top: auto;
        #no-conversation-content {
          width: 80%;
          text-align: center;
          h2 {
            color: $headings;
            font-size: $title-lg;
          }
          p {
            color: $paragraphs;
            font-size: $title-md;
          }
          #no-conversation-avatar-group {
            display: flex;
            justify-content: center;
            margin-bottom: 1.5rem;
            .MuiAvatar-root {
              width: 60px;
              height: 60px;
              img {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .chat-form {
      display: flex;
      flex-direction: row;
      width: 100%; //temp needs to update
      padding-left: 20px; //temp need to update
      padding-right: 20px; //temp need to update

      .chat-form-input-wrapper {
        display: flex;
        flex-direction: column;
        // width: 100%;
        flex-grow: 1;
        min-width: 230px;

        .show-input-files-list {
          .ReactGridGallery_tile-icon-bar {
            display: none;
          }
        }

        .chat-form-text-input-wrapper {
          width: 100%;
          position: relative;
          min-width: 230px;
          opacity: 1;
          background: #edf2fc 0% 0% no-repeat padding-box;
          border-radius: 20px;
          padding: 16px 20px 8px 24px;

          .chat-form-input {
            width: 100%;
            height: 100%;
            background: #edf2fc 0% 0% no-repeat padding-box;
            letter-spacing: 0.38px;
            text-align: left;
            font-family: Roboto, Helvetica Neue, sans-serif; //not using from mockup need to update
            font-weight: 500;
            color: #959ba5;
            border: none;
            resize: none;
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background: $dark;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgb(220, 220, 220);
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
              border-radius: 10px;
            }
          }

          .chat-form-input-encryption-sign {
            position: absolute;
            right: 10px;
            top: 12px;
          }
        }
      }

      .chat-input-action-icons {
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: auto;
        min-width: 210px;
        .chat-input-action-icon {
          margin-left: 11px;
          color: $white;
          background-color: $dark;
          width: 50px;
          height: 50px;
          box-shadow: 0px 1px 2px #00000026;
        }
      }
    }
  }

  .chats-panel {
    min-width: 372px;
    max-width: 400px;
    min-height: 500px;
    background: $light;
    padding: 0px 16px 0px 16px;

    .chat-my-profile {
      height: 182px;
      background: $light;
      box-shadow: none;
      .chat-profile-card-header {
        padding: 16px 8px 16px 8px;
        .chat-settings-icon {
          color: #ffffff;
          font-size: 3rem;
          opacity: 0.7;
        }
        .profile_title-container {
          color: #828190;
          font-family: 'Roboto';
          .profile-name {
            color: $dark;
            font-size: $font-18;
            font-family: 'Roboto-Medium';
          }
          .profile-company {
            color: $dark;
            font-size: $font-14;
            font-family: 'Roboto-Medium';
          }
          .profile-role {
            color: $dark;
            font-size: $font-12;
            font-family: 'Roboto-Medium';
          }
          .profile-location {
            color: $dark;
            font-size: $font-12;
          }
        }

        .chat-my-profile-avatar {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          box-shadow: 0px 3px 8px#fff;
        }
      }

      .chat-profile-card-content {
        padding: 16px 16px 8px 16px;
      }
    }

    .chat-tabs-panel {
      height: calc(100% - 182px);
      #chat-tabpanel-0,
      #chat-tabpanel-1 {
        height: calc(100% - 72px);
        padding-bottom: 5px;

        .private-conversations {
          padding: 10px;
        }

        .conversation-list,
        .contacts-list {
          height: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background: $dark;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgb(220, 220, 220);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            border-radius: 10px;
          }
        }

        .conversation-item {
          padding: 6px 16px 12px 16px;
          .MuiListItemAvatar-root {
            min-width: 42px;
            .conversation-profile-avatar {
              border-radius: 50%;
              width: 29px;
              height: 29px;
              font-size: $font-12;
              box-shadow: 0px 3px 8px #fff;
            }
          }
          .MuiListItemText-root {
            margin: 6px 0 0 0;

            .MuiListItemText-primary {
              color: #828190;
              font-size: $font-14;
            }
            .MuiListItemText-secondary {
              color: #828190;
              font-size: $font-12;
              width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .conversation-time-with-unread-counts {
            display: flex;
            flex-direction: row;
            position: absolute;
            top: 14px;
            right: 17px;
            .unread-messages-counter {
              margin-right: 2px;
              .counter {
                font-size: $font-12;
              }
            }
            .conversation-time-icon {
              color: #a1b4d1;
              line-height: 23.5px;
              margin-right: 4px;
              .MuiSvgIcon-root {
                font-size: 16px !important;
              }
            }
            .conversation-time-text {
              color: #182a63;
              font-size: 12px;
            }
          }
        }
        .contact-item {
          padding: 6px 16px 12px 16px;
          .MuiListItemAvatar-root {
            min-width: 42px;
            .contact-profile-avatar {
              border-radius: 50%;
              width: 29px;
              height: 29px;
              font-size: $font-12;
              box-shadow: 0px 3px 8px #fff;
            }
          }
          .MuiListItemText-root {
            margin: 6px 0 0 0;

            .MuiListItemText-primary {
              color: #828190;
              font-size: $font-14;
            }
          }
        }

        .contact-skeleton,
        .conversation-skeleton {
          .contact-avatar-div,
          .conversation-avatar-div {
            min-width: 42px;
            .contact-avatar {
              width: 29px;
              height: 29px;
            }
          }
          .contact-details-div,
          .conversation-details-div {
            .contact-name {
              width: 250px;
              height: 15px;
            }
            .contact-last-call-date {
              margin-top: 5px;
              width: 75px;
              height: 10px;
            }
            .conversation-last-message {
              margin-top: 5px;
              width: 250px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}

.chat-error {
  margin: 20px 20px 0 0;
}
