.error-fallback {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding: 2rem 4rem;

  h5 {
    text-align: justify;
    text-justify: inter-word;
  }

  .hide-btn {
    margin-top: 1rem;
  }

  code {
    margin-top: 1.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: $yellow;
    font-size: 1rem;
    &#message {
      font-size: 1.5rem;
    }
  }
}
