/**
 * Rotate the hide filmstrip icon so it points towards the right edge
 * of the screen.
 */
.vertical-filmstrip .filmstrip__toolbar {
  transform: rotate(-90deg);
}

/**
 * Overrides for video containers that should not be centered aligned to avoid=
 * clashing with the filmstrip.
 */
.vertical-filmstrip #etherpad,
.vertical-filmstrip #sharedvideo {
  text-align: left;
}

/**
 * Overrides for small videos in vertical filmstrip mode.
 */
.vertical-filmstrip .filmstrip__videos .videocontainer {
  /**
     * Move status icons to the bottom right of the thumbnail.
     */
  .videocontainer__toolbar {
    /**
         * FIXME: disable pointer to allow any elements moved below to still
         * be clickable. The real fix would to make sure those moved elements
         * are actually part of the toolbar instead of positioning being faked.
         */
    pointer-events: none;
    text-align: right;

    > div {
      pointer-events: none;
    }

    .right {
      float: none;
      margin: auto;
    }

    .toolbar-icon {
      pointer-events: all;
    }
  }

  /**
     * Apply hardware acceleration to prevent flickering on scroll. The
     * selectors are specific to icon wrappers to prevent fixed position dialogs
     * and tooltips from getting a new location context due to translate3d.
     */
  .connection-indicator,
  .remote-video-menu-trigger,
  .indicator-icon-container {
    transform: translate3d(0, 0, 0);
  }

  .indicator-icon-container {
    display: inline-block;
  }

  .indicator-container {
    float: none;
  }

  /**
     * Move the remote video menu trigger to the bottom left of the video
     * thumbnail.
     */
  .remotevideomenu,
  .remote-video-menu-trigger {
    bottom: 10px;
    left: 0;
    top: auto;
    right: auto;
    svg {
      width: 14px;
    }
  }

  .remote-video-menu-trigger {
    margin-bottom: 7px;
    margin-left: $remoteVideoMenuIconMargin;
  }
}

/**
 * Overrides for quality labels in filmstrip only mode. The styles adjust the
 * labels' positioning as the filmstrip itself or filmstrip's remote videos
 * appear and disappear.
 *
 * The class with-filmstrip is for when the filmstrip is visible.
 * The class without-filmstrip is for when the filmstrip has been toggled to
 * be hidden.
 * The class opening is for when the filmstrip is transitioning from hidden
 * to visible.
 */
.vertical-filmstrip .large-video-labels {
  &.with-filmstrip {
    right: 150px;
  }

  &.with-filmstrip.opening {
    transition: 0.9s;
    transition-timing-function: ease-in-out;
  }

  &.without-filmstrip {
    transition: 1.2s ease-in-out;
    transition-delay: 0.1s;
  }
}
