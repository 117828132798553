// .new-toolbox {
//     z-index: 9;
//     left: 0;
//     position: absolute;
//     right: 0;
//     transition: bottom .3s ease-in;
//     width: 100%;
// }
.toolbox-background {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  transition: bottom 0.3s ease-in;
  height: 450px;
  width: 100%;
  pointer-events: none;
  z-index: 4;
}
.toolbox-content {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 16px;
  position: relative;
  width: 100%;
  z-index: 350;
}

.button-group-left {
  display: flex;
  width: 33%;
}
.button-group-center {
  justify-content: center;
  display: flex;
  width: 33%;
}
.button-group-right {
  display: flex;
  width: 33%;
}
