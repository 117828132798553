.registration_page {
  .alert-message {
    // padding: 20px 0px 0;
    min-height: 20px;
  }

  .registration_form {
    padding: 30px 80px;

    .MuiGrid-item {
      padding: 2px 8px;

      &.no-padding {
        padding: 0;
      }
    }

    .register_form_input {
      width: 100%;
      margin: 5px 0;

      #job-title-input-label,
      #departments-input-label {
        background: #fafafa;
      }
    }

    .profile-picture {
      width: 136px;
      height: 136px;
      margin: 6px auto;
      img {
        object-fit: cover;
      }
    }

    .profile-avatar {
      display: flex;
      justify-content: center;
      // width: 8.75rem;
      // height: 8.75rem;
      position: relative;
      margin-bottom: 11px;
      .MuiAvatar-root {
        width: 80%;
        height: 80%;
        margin: auto;
      }
      .stats-icon {
        width: 55px;
        height: 55px;
        position: absolute;
        top: 85px;
        right: -10px;
        background: #fff;
        padding: 7px;
        border: 3px solid #94c03d;
        border-radius: 50%;
        .MuiSvgIcon-root {
          font-size: 35px;
          color: #8ea4c6;
        }
      }
    }

    .upload-profile-picture-btn {
      input {
        display: none;
      }
    }

    // .register-form-custom-space {
    //    margin-top: 20px;
    //    p {
    //      font-size: 16px;
    //    }
    // }

    .register-form_submit {
      width: 100%;
      min-height: 110px;
      .btn-submit {
        width: 100%;
        height: 52px;
        margin-top: 10px;
      }
      p {
        font-size: 16px;
      }
    }

    .react-tel-input {
      input {
        background: transparent;
      }
    }

    // Disable increment arrwos on PIN input
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
