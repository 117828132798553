.projectDetails {
  // width: 45%;
  .topBreadCrumb {
    display: flex;
    margin-top: 20px;
  }

  .middle {
    .middleP1 {
      color: gray;
    }
    .middleP2 {
      margin-top: 30px;
      cursor: pointer;
    }
    .middleP3 {
      color: gray;
      margin-top: 4px;
      margin-bottom: 4px;
      cursor: pointer;
    }

    .tree {
      margin-top: 20px;
      width: 1100px;
      .middleLine {
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .storySection {
        cursor: pointer;
      }
    }
  }
}

.epicDiv {
  display: flex;
  justify-content: space-around;
  .div1_1 {
    width: 140%;
  }
  .div2_1 {
    button {
      background-color: transparent;
      border: none;
      margin-left: -40px;
      position: absolute;
      cursor: pointer;
    }
  }
  .editIcon1 {
    display: none;
  }
}
.epicDiv:hover {
  .editIcon1 {
    display: flex;
    margin-top: 3px;
    height: 19px;
  }
}

.tremorDiv {
  display: flex;
  justify-content: space-around;
  .div1_2 {
    width: 140%;
  }
  .div2_2 {
    button {
      background-color: transparent;
      border: none;
      margin-left: -40px;
      position: absolute;
      cursor: pointer;
    }
  }
  .editIcon2 {
    display: none;
  }
}
.tremorDiv:hover {
  .editIcon2 {
    display: flex;
    margin-top: 3px;
    height: 19px;
  }
}

.storyDiv {
  display: flex;
  justify-content: space-around;
  .div1_3 {
    width: 140%;
  }
  .div2_3 {
    button {
      background-color: transparent;
      border: none;
      margin-left: -40px;
      position: absolute;
      cursor: pointer;
    }
  }
  .editIcon3 {
    display: none;
  }
}
.storyDiv:hover {
  .editIcon3 {
    display: flex;
    margin-top: 3px;
    height: 19px;
  }
}

///////////////
// side-panel
///////////////

.sidePanel {
  width: 610px;
  // margin-top: 20%;

  .sidePanelTopButton {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: 25px;
    height: 25px;
    position: absolute;
    opacity: 2;
    background-color: white;
    z-index: 9;
    margin-left: -10px;
    cursor: pointer;
  }
  .field {
    width: 100%;
    margin-top: 20px;
  }
  .inputDescription {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    font-family: sans-serif;
    font-size: 14px;
  }
  .inputDescript {
    height: 30px;
  }
  .button {
    margin-top: 20px;
    margin-left: 415px;
  }
  .sidePanelTop {
    background-color: #f3f6fa;
    height: 120px;
    padding: 20px 20px 0;
  }
  .sidePanelTopHeading {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    h6 {
      margin-top: 10px;
    }
    button {
      width: 150px;
    }
  }
  .middle {
    padding: 20px;
  }

  .topSelect {
    height: 35px;
    width: 200px;
  }
  .grid1 {
    margin-top: 10px;
    margin-left: -10px;
    margin-bottom: 10px;
    justify-content: space-around;
  }
  .gridRight {
    color: gray;
    margin-top: 10px;
  }
  .gridLeft {
    display: block;
  }
  .markButton {
    margin-top: 10px;
    margin-left: 35px;
  }
  .gridSection {
    margin-top: 10px;
    display: flex;
  }
  .gridStrong {
    width: 100px;
  }
  .timeTrackerPara {
    color: gray;
  }
  .accor {
    box-shadow: none !important;
    border: none !important;
  }
  .accorHeading {
    margin-left: -12px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .accorDetails {
    padding: 5px;
    margin-top: 12px;
  }
  .timeTrackerHr {
    margin: 7px 0;
  }
  .timeTrackerHr1 {
    margin: -5px 0 8px;
    border-top: 1px solid #94c03d;
  }
  .QNASection {
    margin-top: 20px;
  }
  .QNASection1 {
    display: flex;
  }
  .QNASectionImg {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  .QNASectionStrong {
    margin-right: 70px;
    margin-left: 10px;
  }

  .QNASectionPara1 {
    margin-top: 0px;
    color: lightgrey;
  }
  .QNASectionPara2 {
    margin-top: 2px;
    margin-left: 30px;
    color: gray;
  }
}
