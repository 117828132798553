.project-avatar {
  .contactInfo {
    margin-left: 2px;
  }
  .avatar-img {
    max-width: 100% !important;
  }
}

.project-row {
  margin-bottom: -33px;
  min-height: 30px;
  position: relative;
  z-index: 2 !important;
  width: 600px;
  left: 100%;
  transform: translateX(-100%);
  display: flex;
  justify-content: flex-end;
  padding-right: 27px;
  top: 9px;
  .div1_2 {
    width: 140%;
  }

  .element-actions {
    position: relative;
    z-index: 290;

    display: flex;
  }
}
// .project-row:hover {
//   .element-actions {
//     display: flex;
//   }
// }

.element-actions:hover {
  .element-actions {
    display: flex;
  }
}

.project-details-container .MuiTreeItem-content {
  padding: 5px 0;
}

.popperContainer {
  position: relative;
  z-index: 10000 !important;
}

.popperContainer > div {
  position: relative !important;
  transform: none !important;
  z-index: 10000 !important;
}

.element-actions button {
  margin-left: 5px;
  zoom: 0.9;
  background: white;
}

.project-filters .amera-select .MuiOutlinedInput-input {
  padding: 8px;
}
.project-filters .amera-select .MuiInputLabel-outlined {
  transform: translate(8px, 12px) scale(1);
  font-size: 12px;
}
.project-filters .MuiGrid-item {
  width: 130px;
}

.row-icon {
  z-index: 22;
  position: relative;
  top: -5px;
  left: 17px;
}

.element-row {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 6px;
}

.element-row.epic-row {
  height: 40px;
  display: flex;
  background: white;
  align-items: center;
  padding-left: 40px;
  border-radius: 6px;
  box-shadow: 0px 6px 7px 0px #00000012;
  font-size: 20px;
  left: -20px;
  position: relative;
  z-index: 1;
}

.element-row.epic-row p.row-title {
  font-size: 15px !important;
  font-weight: 500;
}

.tree-row
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  background: none;
}

.tree-row-task .row-icon,
.tree-row-milestone .row-icon {
  display: none;
}
/*
.task-row.element-row,
.milestone-row.element-row {
  background: white;
  left: -20px;
  position: relative;
}
*/

.task-row-items {
  background: white;
  left: -20px;
  position: relative;
  padding: 8px 10px;
  border-radius: 5px;
}
.task-row-items.complete {
  background: #eaeaea7d;
}

.task-row .profile-area,
.milestone-row .profile-area {
  display: flex;
}
.task-name,
.task-avatar,
.task-due-date,
.task-total-hours {
  color: #2e2a60;
  margin-left: 8px;
  font-size: 15px;
}
.task-avatar,
.task-due-date span,
.task-total-hours span {
  color: #8083a3 !important;
  margin-left: 3px;
}

.material-avatar.task-row {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-top: 2px;
}

.fixed-pill {
  border-radius: 4px;
  margin-left: 20px !important;
  padding: 2px 6px;
  color: #35a031;
  background: #e3f5d6;
  font-size: 10px;
}
