.main {
  width: 100%;
  min-height: calc(100vh - 120px);
  display: flex;
  .main-content {
    position: relative;
    background-color: $light;
    flex: auto 1 1;
    .page_content {
      // height: 100%;
      min-height: calc(100vh - 174px);
      display: flex;
      .content {
        width: 100%;
        // min-height: calc(100vh - 160px);
        background: #f8faff;
        border-radius: 15px;
        padding: 0 0 20px 20px;
        margin-left: 20px;
        margin-top: 20px;
        margin-right: 20px;
        &.video-call {
          padding: 0;
        }
      }
      .content-dashboard {
        width: 100%;
        min-height: calc(100vh - 174px);
        margin: 26px 30px 5px 28px;
        // height: fit-content;
      }
    }
  }
}

.align-center {
  align-items: center;
}

.invite-btn {
  font-size: 20px;
  padding: 8px 8px;
  color: #fff;
}

// group page style
.group-list {
  .card {
    cursor: pointer;
    .card-header {
      font-size: 16px;
      font-weight: 400;
      font-family: monospace;
    }
    .card-body .list-group .list-group-item {
      font-size: 14px;
    }
  }
}

.group-detail-modal {
  .modal-header {
    .modal-title {
      font-size: 22px;
    }
    button span {
      font-size: 22px;
    }
  }
  .card-header {
    font-weight: 500;
  }
  .list-group-item {
    .remove-member-btn {
      float: right;
    }
  }

  .btn {
    float: right;
    font-size: 12px;
    font-weight: 500;
  }

  .sort-group-members {
    display: flex;
    position: absolute;
    right: 2px;
    top: 3px;
    label {
      font-size: 14px;
      margin-top: 5px;
    }
    select {
      font-size: 14px;
      width: 120px;
    }
  }
  select {
    font-size: 14px;
  }

  .form-label:after {
    content: '*';
    margin-left: 2px;
    color: red;
  }
}

.dark-icon {
  fill: $dark;
}

.view-section {
  .Mui-selected {
    background: #94c03d !important;

    .MuiToggleButton-label {
      color: #fff;
    }
  }

  .toggle-button-group {
    display: flex;
    justify-content: space-between;
    padding: 20px 8px 0 8px;
  }
}

.card-pagination {
  .MuiPaginationItem-page {
    border: solid 1px #94c03d;
    color: #94c03d;
    font-size: 14px;
  }

  .Mui-selected {
    background: #94c03d !important;
    color: #fff !important;
  }

  .MuiPaginationItem-icon {
    font-size: 14px;
  }
}

.debug-container {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
}

.newsfeeds-reply {
  color: #94c03d;
  margin-left: 8px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}

.comment-link {
  cursor: pointer;
}

.contact-preview-avatar-img {
  margin-right: 8px !important;
}

.layout-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
