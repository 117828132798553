$active: $sushi-color;
$font-size-base: 1.1rem;
$font-size-small: 1rem;

// @import '~chonky/style/main.scss';

.chonky-file-entry-description-title-modifiers,
.chonky-file-entry-column-name-modifiers {
  color: $sushi-color !important;
}

.chonky-baseButton {
  padding: 6px 8px !important;
}
