.picture-pin-container {
  // Disable increment arrwos on PIN input
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .noborder {
    border: none !important;
  }

  .picture-container {
    width: 100%;
    height: 18rem;
    background: transparent;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all 0.2s ease;

    .content-container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 100%;

      .select-file {
        text-transform: unset;
      }

      img {
        height: 100%;
        object-fit: contain;
      }

      // .MuiCardMedia-root {
      //   height: 100%;
      //   .MuiCardMedia-img {
      //     object-fit: contain;
      //   }
      // }
    }
  }

  .helper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    .pin-counter {
      font-size: 0.8em;
      &.disabled {
        display: none;
      }

      &.error {
        color: $red;
      }

      &.success {
        color: green;
      }
    }
  }

  .exchange-header {
    font-weight: bold;
  }
  .exchange {
    width: 100%;
    border: 1px solid lightgray;
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    label {
      font-size: 0.9rem;
      span {
        font-size: 0.9rem;
      }
    }
  }
}
