#sideToolbarContainer {
  background-color: $white;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: $sidebarWidth;
  z-index: $sideToolbarContainerZ;

  /**
     * The sidebar (chat) is off-screen when hidden. Move it flush to the left
     * side of the window when it should be visible.
     */
  &.slideInExt {
    left: 0;
  }
}

#chatconversation {
  box-sizing: border-box;
  flex: 1;
  font-size: 10pt;
  line-height: 20px;
  overflow: auto;
  padding: 16px;
  text-align: left;
  width: $sidebarWidth;
  word-wrap: break-word;

  a {
    display: block;
  }

  a:link {
    color: rgb(184, 184, 184);
  }

  a:visited {
    color: white;
  }

  a:hover {
    color: rgb(213, 213, 213);
  }

  a:active {
    color: black;
  }

  &::-webkit-scrollbar {
    background: #06a5df;
    width: 7px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: black;
  }

  &::-webkit-scrollbar-track-piece {
    background: black;
  }

  &::-webkit-scrollbar-thumb {
    background: #06a5df;
    border-radius: 4px;
  }
}

#chat-recipient {
  align-items: center;
  background-color: $chatPrivateMessageBackgroundColor;
  display: flex;
  flex-direction: row;
  font-weight: 100;
  padding: 10px;

  span {
    color: white;
    display: flex;
    flex: 1;
  }

  div {
    svg {
      cursor: pointer;
      fill: white;
    }
  }
}

.chat-header {
  background-color: $primary;
  height: 70px;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  .jitsi-icon {
    cursor: pointer;
  }

  .jitsi-icon > svg {
    fill: $white;
  }
}

#chat-input {
  border-top: 1px solid $chatInputSeparatorColor;
  display: flex;
  padding: 0 10px 0;

  * {
    background-color: transparent;
  }
}

.remoteuser {
  color: #b8c7e0;
}

.usrmsg-form {
  flex: 1;
}

#usermsg {
  border: 0px none;
  border-radius: 0;
  box-shadow: none;
  color: #333;
  font-size: 15px;
  padding: 10px 10px 0;
  overflow-y: auto;
  resize: none;
  width: 100%;
  word-break: break-word;
}

#usermsg:hover {
  border: 0px none;
  box-shadow: none;
}

#nickname {
  text-align: center;
  color: #9d9d9d;
  font-size: 18px;
  margin-top: 30px;
  left: 5px;
  right: 5px;
}

.sideToolbarContainer {
  * {
    -webkit-user-select: text;
    user-select: text;
  }

  .display-name {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 580px) {
    display: none !important;
  }
}

.chatmessage {
  background-color: $chatRemoteMessageBackgroundColor;
  border-radius: 0px 6px 6px 6px;
  box-sizing: border-box;
  color: white;
  margin-top: 3px;
  max-width: 100%;
  position: relative;

  &.localuser {
    background-color: $chatLocalMessageBackgroundColor;
    border-radius: 6px 0px 6px 6px;
  }

  .usermessage {
    white-space: pre-wrap;
  }

  &.error {
    border-radius: 0px;

    .timestamp,
    .display-name {
      display: none;
    }

    .usermessage {
      color: red;
      padding: 0;
    }
  }

  .privatemessagenotice {
    font-size: 11px;
    font-weight: 100;
  }

  .messagecontent {
    margin: 5px 10px;
    max-width: 100%;
    overflow: hidden;
  }
}

.smiley {
  font-size: 14pt;
}

#smileys {
  font-size: 20pt;
  cursor: pointer;
}

#smileys img {
  width: 22px;
  padding: 2px;
}

#smileysarea {
  display: flex;
  max-height: 150px;
  min-height: 35px;
  overflow: hidden;
}

.smiley-input {
  display: flex;
  position: relative;
}

.smileys-panel {
  bottom: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: auto;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  width: $sidebarWidth;

  /**
     * CSS transitions do not apply for auto dimensions. So to produce the css
     * accordion effect for showing and hiding the smiley-panel, while allowing
     * for variable panel, height, use a very large max-height and animate off
     * of that.
     */
  transition: max-height 0.3s;

  &.show-smileys {
    max-height: 500%;
  }

  #smileysContainer {
    background-color: $newToolbarBackgroundColor;
    border-bottom: 1px solid;
    border-top: 1px solid;
  }
}

#smileysContainer .smiley {
  font-size: 20pt;
}

.smileyContainer {
  width: 40px;
  height: 36px;
  display: inline-block;
  text-align: center;
}

.smileyContainer:hover {
  background-color: $newToolbarButtonToggleColor;
  border-radius: 5px;
  cursor: pointer;
}

#usermsg::-webkit-scrollbar-track-piece {
  background: #3a3a3a;
}

.chat-message-group {
  display: flex;
  flex-direction: column;

  &.local {
    align-items: flex-end;

    .chatmessage {
      background-color: $chatLocalMessageBackgroundColor;
      border-radius: 6px 0px 6px 6px;

      &.privatemessage {
        background-color: $chatPrivateMessageBackgroundColor;
      }
    }

    .display-name {
      display: none;
    }
  }
  .timestamp {
    text-align: right;
    color: #333;
    margin-bottom: 10px;
  }
  &.error {
    .chatmessage {
      background-color: $defaultWarningColor;
      border-radius: 0px;
      font-weight: 100;
    }

    .display-name {
      display: none;
    }
  }

  .chatmessage-wrapper {
    max-width: 100%;
    width: 100%;
    .replywrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .messageactions {
        align-self: stretch;
        border-left: 1px solid $chatActionsSeparatorColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;

        .toolbox-icon {
          cursor: pointer;
        }
      }
    }
  }

  .chatmessage {
    background-color: $chatRemoteMessageBackgroundColor;
    border-radius: 0px 6px 6px 6px;
    display: inline-block;
    margin-top: 3px;
    color: white;
    width: 100%;

    &.privatemessage {
      background-color: $chatPrivateMessageBackgroundColor;
    }
  }
}

.chat-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -5px; // Margin set by atlaskit.

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px 24px;
    width: calc(100% - 32px);
    box-sizing: border-box;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    .jitsi-icon {
      cursor: pointer;
    }

    .jitsi-icon > svg {
      fill: #a4b8d1;
    }
  }

  #chatconversation {
    width: 100%;
  }
}

/**
 * Make header close button more easily tappable on mobile.
 */
.mobile-browser .chat-dialog-header .jitsi-icon {
  display: grid;
  place-items: center;
  height: 48px;
  width: 48px;
  background: #2a3a4b;
  border-radius: 3px;
}
