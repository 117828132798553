.material-avatar {
  background: $dark;
  &.header {
    font-size: 2rem;
    height: 5rem;
    width: 5rem;
    border: 3px solid white;
    border-radius: 12.5px;
  }
  &.email-preview {
    font-size: 1.5rem;
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 9px;
    box-shadow: 1px 1px 9px 1px #c2c3c7;
    border: 3px solid white;
  }
  &.email-preview-draft {
    font-size: 1.5rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 7px;
    box-shadow: 1px 1px 9px 1px #c2c3c7;
    border: 2px solid white;
  }
  &.contact {
    font-size: 1.5rem;
    height: 4.75rem;
    width: 4.75rem;
    border-radius: 9px;
  }
  &.profile {
    font-size: 3.5rem;
    height: 8.75rem !important;
    width: 8.75rem !important;
    border-radius: 25px;

    &.my {
      cursor: pointer;
    }
  }
}
