@import 'react-flags-select/scss/react-flags-select.scss';

.flag-select__option__icon {
  top: 0;
}

.flag-select__btn {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 22px !important;
}
