@keyframes opacity-calm {
  from {
    background-color: darkgreen;
  }
  to {
    background-color: $sushi-color;
  }
}

.header {
  height: 120px;
  width: 100%;
  display: flex;
  z-index: 12;
  position: relative;
  background-color: #ffffff;
  box-shadow: 1px 1px 9px 1px #c2c3c7;

  .headerBgText {
    font-size: 6.5rem;
    font-weight: 600;
    color: $sushi-color;
    margin-right: 2rem;
  }

  .logo {
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;
    flex: none;
    justify-content: center;
    border-right: 1px solid #e5e8f7;
  }

  &-items {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    text-transform: capitalize;

    .drawer-triggers {
      .MuiButtonBase-root {
        padding: 11px 28px;
        font-size: 15px;
        font-weight: 700;
        margin-right: 15px;
        text-transform: none;
      }
      .MuiButtonBase-root:first-child {
        margin-left: 15px;
      }
      .MuiButton-label {
        white-space: nowrap;
      }
    }
    .select-language {
      padding: 0 20px;
      * {
        font-size: 18px;
      }
      .flag-select__options {
        margin: 0;
        max-height: fit-content;
        box-shadow: 0px 3px 6px #d4e2f0;
        border: 1px solid #dedede;
        border-radius: 0px 0px 20px 20px;
        .has-label {
          color: #505050;
          text-align: center;
        }
      }
      .flag-select__option__icon {
        display: none;
      }
      .flag-select__option__label {
        padding: 0;
      }
      .flag-select__btn {
        border-radius: 5px;
        border: 1px solid #dedede;
      }
    }
    .call-notification {
      height: 70px;
      text-transform: none;
      display: flex;
      align-items: center;
      border-radius: 7px;
      padding-left: 20px;
      color: white;
      white-space: nowrap;
      letter-spacing: 0.36px;
      font-weight: bolder;
      .label {
        font-size: 18px;
        margin-right: 20px;
      }
      &.upcoming {
        background-color: $green;
        box-shadow: 0px 5px 15px $dark;
        animation: opacity-calm 2000ms cubic-bezier(0.39, 0.575, 0.565, 1)
          infinite running;

        .time {
          font-size: 18px;
          white-space: nowrap;
          margin: 0px 10px 0px 10px;
        }

        .action-box {
          display: flex;
          align-items: center;

          .go-btn {
            font-size: 2rem;
            color: #fff;
          }
        }
      }
      &.no-calls {
        background-color: $green;
        box-shadow: 0px 5px 15px $dark;
      }
    }

    .phone-call {
      margin-left: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $green;
      font-size: 16px;
      font-weight: bold;
      min-width: 200px;
      .phone-icon {
        width: 32px;
        height: 32px;
        path {
          fill: $green !important;
        }
      }
      .callee-name {
        margin-left: 8px;
        margin-right: 16px;
      }
      .call-duration {
        margin-left: 16px;
        margin-right: 8px;
      }
      .MuiIconButton-root {
        background: #c82333;
        color: white;
        cursor: pointer;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      .profile-dropdown {
        text-decoration: none;
        font-size: 24px;
        font-weight: bold;

        color: #182a63;
        span {
          margin-left: 20px;
          color: $dark;
        }
      }

      .invite-btn {
        margin-right: 10px;
      }
    }
  }

  .header-dropdown {
    font-size: 16px;
    z-index: 13;
    a {
      padding: 2px 25px;
    }
  }

  .header-dropdown.dropdown-menu {
    display: none;
  }

  .header-dropdown.dropdown-menu.show {
    display: block;
  }
}

.flag-select__option__icon {
  display: none;
}

.notification-popover {
  padding: 10px 10px 0px 10px;
  .upcoming {
    background: #94c03d 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #a1abc681;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 15px;
  }

  .notification {
    background: #623aeb; // transparent linear-gradient(180deg, #623aeb 0%, #dce1ec 100%)
    box-shadow: 0px 4px 12px #a1abc680;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 15px;
  }

  .canceled {
    background: #d83a3a; // transparent linear-gradient(341deg, #d83a3a 0%, #f69062 100%)
    box-shadow: 0px 4px 12px #a1abc680;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 15px;

    .MuiCardContent-root {
      .canceled-member {
        span {
          color: #fff !important;
        }
      }
    }
  }

  .MuiCard-root {
    .MuiCardHeader-root {
      .MuiCardHeader-content {
        span {
          font-size: 1rem;
          color: #fff;
        }

        .MuiCardHeader-title {
          font-weight: 700;
        }
        .MuiCardHeader-subheader {
          font-weight: 600;
          opacity: 0.7;
        }
      }
      .MuiCardHeader-action {
        margin-top: -18px;
        .MuiIconButton-root {
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              font-size: 2rem;
              color: #fff;
              opacity: 0.7;
            }
          }
        }
      }
    }

    .MuiCardContent-root {
      padding-top: 0px;
      .MuiGrid-item {
        .MuiListItem-container {
          .MuiListItem-gutters {
            span {
              color: #2e2a60;
              font-size: 14px;
            }
          }
          .MuiIconButton-label {
            color: #fff;
          }
        }

        .MuiListItemIcon-root {
          min-width: 20px;
          .dot-icon {
            font-size: 10px;
            color: #ffffff;
          }
        }

        .notification-member {
          span {
            font-size: 14px;
            color: #fff;
            min-width: 100px;
            border-right: 1px solid rgba(255, 255, 255, 0.7);
          }
        }

        .notification-group {
          span {
            margin-left: 20px;
            color: #cfe3a7;
            // opacity: 0.7;
          }
        }

        .canceled-member {
          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }

      .canceled-date {
        .MuiListItemIcon-root {
          min-width: 30px;
          color: #fff;
        }
        div {
          span {
            font-size: 13px;
            color: #fff;
          }
        }
      }
    }
  }
}
