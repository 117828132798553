.with-counter {
  position: relative;
}

.header-icon {
  span {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  margin: 15px;
}

.sidebar-icon {
  span {
    position: absolute;
    top: 0;
    right: 20px;
    border: 2px solid white;
  }
}
