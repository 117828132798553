.default-modal {
  .MuiPaper-root {
    min-width: 500px;
    border-radius: 16px;
  }
  .MuiDialogTitle-root {
    .modal-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .header-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .header_title {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 30px;
        font-weight: 600;
      }
    }
    .header-right {
      display: flex;
      justify-content: flex-end;

      .close_button {
        width: 30px;
        height: 30px;
        top: 18px;
        color: #9e9e9e;
        right: 18px;
        position: absolute;
        .MuiSvgIcon-root {
          font-size: 14px;
          color: #848ac1;
        }
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .MuiDialogContent-root {
    padding: 16px !important;
    border: none !important;
    // overflow-y: initial !important;
    overflow-y: auto;
    .modal-header-dropdown {
      position: absolute;
      width: 120px;
      top: 42px;
      right: 16px;
    }
    .modal-header-button {
      position: absolute;
      width: 110px;
      top: 54px;
      right: 16px;
    }
  }

  .actions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .action-button {
      width: 130px;
      margin: 8px;
    }
  }
}

.facial-recognition-modal {
  .MuiDialogContent-root {
    padding: 0 !important;
  }
}

// define individual modal style
.member-invite-modal {
  .member-invite-email-field {
    .MuiFormControl-root {
      width: 416px;
    }
  }
}

.otp-verification-modal {
  .circle-timer {
    margin: 0 0.5rem;
  }
  .OTP-section {
    margin: 1.5rem 2rem;

    .OTP-container {
      justify-content: space-evenly;
      margin-bottom: 0.5rem;
      .OTP-input {
        font-size: 3.75rem;
        padding: 0.4rem;
        background: $primary;
        text-decoration: none;
        border-width: 1px;
        border-radius: 0.75rem;
        border-color: $gray;
        &::selection {
          color: none;
          background: none;
        }
      }
      .OTP-input-focused {
        user-select: none;
        border: 1px solid $sushi-color;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $sushi-color;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
      .OTP-input-error {
        border: 1px solid $red;
      }
    }

    .OTP-error-helper {
      margin-left: 2rem;
    }

    .alert-block {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.promo-modal {
  .input-container {
    margin: 1rem 4rem;

    #promo-code {
      max-width: 10rem;
    }
  }

  .applied-typography {
    margin: 1rem 0;
  }

  .promo-code-name {
    font-weight: bold;
    margin-right: 1rem;
  }
}

.terms-modal {
  .contract-text-comtainer {
    font-size: 0.8rem;
    margin: 0 0.5rem;
    padding: 0.5rem;
    background: $light;
    border-radius: 0.5rem;
    text-align: justify;
    text-justify: inter-word;
    max-height: 20rem;
    overflow-y: scroll;
  }
}

// Upcoming event modal
.upcoming-event-modal {
  user-select: none;
  .content {
    padding: 0 0.8rem;

    .name-time-line {
      padding: 0 1rem 1rem 1rem;
      .watch-icon {
        margin-right: 0.5rem;
      }
    }

    .participants-toogle {
      text-transform: none;
      font-size: 1rem;
    }
  }
  .action-buttons {
    padding: 0.8rem;

    .btn {
      text-transform: none;
      border-radius: 1rem;

      &.going {
        &:hover {
          color: white;
        }
      }

      &.cant {
        color: white;
        background: black;
      }

      &.late {
        background: white;
        border: 1px solid black;
      }
    }
  }
}

// define individual contact details modal style
.modal-contact-details {
  .modal-title {
    font-weight: 600;
  }
  .roles-select-dropdown {
    position: absolute;
    width: 120px;
    top: 79px;
    right: 16px;
  }
}

.modal-contact-security-details {
  .MuiPaper-root {
    width: 60vw;
    min-width: unset;
    max-width: unset;
  }
}

.profile-modal {
  .modal-title {
    font-weight: 600;
  }
  .modal-action-btn {
    margin-left: 1rem;
    width: 130px;
  }
}

.profile-edit-modal {
  .MuiPaper-root {
    width: 45rem;
    min-width: unset;
    max-width: unset;

    // For alert
    &.MuiAlert-root {
      width: unset;
    }
  }
  .profile_page-body {
    padding: 0.5rem;

    .profile-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .namefield-container {
      width: 32%;
      .profile-edit-namefield {
        width: 100%;
      }
    }

    #job-title-input-label,
    #departments-input-label {
      background: #fafafa;
    }

    .job-details-container {
      justify-content: space-between;
      padding: 8px 0;
      .job-details-item {
        width: 32%;
      }
    }

    .phone-details-container {
      padding: 0;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.crop-modal {
  .MuiPaper-root {
    width: 50vw;
    .react-crop-container {
      max-height: 50vh;
      min-height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .react-crop,
      .image-view {
        width: auto;
        div {
          height: 100%;
        }
        img {
          max-width: 100%;
          min-height: 320px;
          max-height: 50vh;
        }
      }
    }
    .actions-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 6px 16px;
    }
  }
}

.new-modal {
  padding-bottom: 0px !important;
  .MuiPaper-root {
    border-radius: 0px 4px 4px 0px;
    display: flex;
    flex-direction: row;
  }
  .left-space {
    width: 2%;
    background-color: #94c03d;
  }
  .MuiDialogTitle-root {
    .modal-header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
    .header-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .header_title {
        margin-top: auto;
        margin-left: 5px;
        font-weight: 600;
        color: #2e2a60;
      }
    }
    .header-right {
      display: flex;
      justify-content: flex-end;

      .close_button {
        width: 30px;
        height: 30px;
        color: #9e9e9e;
        position: inherit;
        border: 2px solid black;
        .MuiSvgIcon-root {
          font-size: 14px;
          color: #848ac1;
        }
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0px 16px 0px 16px !important;
    border: none !important;
    // overflow-y: initial !important;
    overflow-y: auto;
    .modal-header-dropdown {
      position: absolute;
      width: 120px;
      top: 42px;
      right: 16px;
    }
    .modal-header-button {
      position: absolute;
      width: 110px;
      top: 54px;
      right: 16px;
    }
  }

  .actions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .action-button {
      width: 130px;
      margin: 8px;
    }
  }
}

// define individual modal style
.member-invite-modal {
  .member-invite-email-field {
    .MuiFormControl-root {
      width: 416px;
    }
  }
}

.otp-verification-modal {
  .circle-timer {
    margin: 0 0.5rem;
  }
  .OTP-section {
    margin: 1.5rem 2rem;

    .OTP-container {
      justify-content: space-evenly;
      margin-bottom: 0.5rem;
      .OTP-input {
        font-size: 3.75rem;
        padding: 0.4rem;
        background: $primary;
        text-decoration: none;
        border-width: 1px;
        border-radius: 0.75rem;
        border-color: $gray;
        &::selection {
          color: none;
          background: none;
        }
      }
      .OTP-input-focused {
        user-select: none;
        border: 1px solid $sushi-color;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $sushi-color;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
      .OTP-input-error {
        border: 1px solid $red;
      }
    }

    .OTP-error-helper {
      margin-left: 2rem;
    }

    .alert-block {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.promo-modal {
  .input-container {
    margin: 1rem 4rem;

    #promo-code {
      max-width: 10rem;
    }
  }

  .applied-typography {
    margin: 1rem 0;
  }

  .promo-code-name {
    font-weight: bold;
    margin-right: 1rem;
  }
}

.terms-modal {
  .contract-text-comtainer {
    font-size: 0.8rem;
    margin: 0 0.5rem;
    padding: 0.5rem;
    background: $light;
    border-radius: 0.5rem;
    text-align: justify;
    text-justify: inter-word;
    max-height: 20rem;
    overflow-y: scroll;
  }
}

// Upcoming event modal
.upcoming-event-modal {
  user-select: none;
  .content {
    padding: 0 0.8rem;

    .name-time-line {
      padding: 0 1rem 1rem 1rem;
      .watch-icon {
        margin-right: 0.5rem;
      }
    }

    .participants-toogle {
      text-transform: none;
      font-size: 1rem;
    }
  }
  .action-buttons {
    padding: 0.8rem;

    .btn {
      text-transform: none;
      border-radius: 1rem;

      &.going {
        &:hover {
          color: white;
        }
      }

      &.cant {
        color: white;
        background: black;
      }

      &.late {
        background: white;
        border: 1px solid black;
      }
    }
  }
}

// define individual contact details modal style
.modal-contact-details {
  .modal-title {
    font-weight: 600;
  }
  .roles-select-dropdown {
    position: absolute;
    width: 120px;
    top: 79px;
    right: 16px;
  }
}

.modal-contact-security-details {
  .MuiPaper-root {
    width: 60vw;
    min-width: unset;
    max-width: unset;
  }
}

.modal-keygen-demo {
  .MuiPaper-root {
    // width: 60vw;
    min-width: unset;
    max-width: unset;
  }
}

.profile-modal {
  .modal-title {
    font-weight: 600;
  }
  .modal-action-btn {
    margin-left: 1rem;
    width: 130px;
  }
}

.profile-edit-modal {
  .MuiPaper-root {
    width: 45rem;
    min-width: unset;
    max-width: unset;

    // For alert
    &.MuiAlert-root {
      width: unset;
    }
  }
  .profile_page-body {
    padding: 0.5rem;

    .profile-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .namefield-container {
      width: 32%;
      .profile-edit-namefield {
        width: 100%;
      }
    }

    #job-title-input-label,
    #departments-input-label {
      background: #fafafa;
    }

    .job-details-container {
      justify-content: space-between;
      padding: 8px 0;
      .job-details-item {
        width: 32%;
      }
    }

    .phone-details-container {
      padding: 0;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.crop-modal {
  .MuiPaper-root {
    width: 50vw;
    .react-crop-container {
      max-height: 50vh;
      display: flex;
      justify-content: center;
      .react-crop {
        width: auto;
        margin-left: auto;
        margin-right: auto;

        div {
          height: 100%;
        }
        img {
          max-height: 50vh;
        }
      }
    }
  }
}
