.scheduler {
  display: flex;
  justify-content: center;
  &-head {
    &__row {
      display: flex;
      align-items: center;
      height: 50px;
    }

    &__title {
      min-width: 110px;
      padding-left: 15px;
    }

    &__cells {
      display: flex;
    }

    &__cell {
      min-width: 30px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px solid #dce1ec;
      width: 40px;
      text-align: center;
    }
  }

  &-body {
    &__row {
      display: flex;
      align-items: center;
      height: 50px;
    }

    &__title {
      min-width: 110px;
      background: white;
      height: 50px;
      border: 1px solid #dce1ec;
      padding-left: 15px;
      padding-top: 5px;
      margin-right: 10px;
      font-weight: bold;

      span {
        font-weight: 400;
        font-size: 11px;
      }
    }

    &__cells {
      display: flex;
    }

    &__cell {
      min-width: 30px;
      height: 50px;
      border: 1px solid #dce1ec;
      background: white;
      cursor: pointer;
      width: 40px;

      &.color-fun {
        background: #1c5de1;
      }
      &.color-friend {
        background: #7747e3;
      }
      &.color-family {
        background: #dd1e42;
      }
      &.color-working {
        background: #fd7e14;
      }
      &.color-work {
        background: #94c03d;
      }
      &.color-event {
        background: #eb367e;
      }
      &.color-sleep {
        background: #6c757d;
      }
      &.color-erase {
        background: #ffffff;
      }
    }
  }
}
