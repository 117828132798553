.profile_page-body {
  // display: flex;
  .profile-picture {
    width: 136px;
    height: 136px;
    margin: auto;
  }
  .show-more-link {
    text-decoration: none;
    color: #2694c7;
  }

  .upload-profile-picture-btn {
    input {
      display: none;
    }
  }

  .profile_avatar-container {
    width: 100%;
    .profile-avatar-container {
      display: flex;
      justify-content: center;
    }
    .profile-avatar {
      display: flex;
      justify-content: center;
      width: 8.75rem;
      height: 8.75rem;
      position: relative;
      margin-bottom: 1rem;
      .MuiAvatar-root {
        width: 80%;
        height: 80%;
        margin: auto;
      }
      .stats-icon {
        width: 55px;
        height: 55px;
        position: absolute;
        text-align: center;
        top: 85px;
        right: -10px;
        background: #fff;
        padding: 7px;
        border: 3px solid #94c03d;
        border-radius: 50%;
        .MuiIconButton-root {
          padding: 1px !important;
        }
        .MuiSvgIcon-root {
          font-size: 35px;
          color: #8ea4c6;
        }
      }
    }

    .action-icons {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .action-icon {
        width: 36px;
        height: 36px;
        // margin: auto;
        background-color: #8ea4c6;
        color: #fff;
        // margin: 0 16px;
        margin-bottom: 3px;
      }
    }
  }

  .profile_title-container {
    text-align: center;
    color: rgba(0, 0, 0, 0.54);

    .profile-name {
      font-size: 22px;
      font-weight: 600;
    }
    .profile-company {
      font-size: 20px;
      font-weight: 500;
    }
    .profile-role {
      font-size: 18px;
      font-weight: 400;
    }
    .profile-location {
      font-size: 18px;
    }
  }

  .text-container {
    color: rgba(0, 0, 0, 0.54);
    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .sub-title {
      font-size: 15px;
      font-weight: 600;
      margin-left: 20px;
      padding: 2px 0;
    }

    .sub-content {
      margin-left: 20px;
      font-size: 14px;
    }

    .sub-title-no-space {
      font-size: 15px;
      font-weight: 600;
      padding: 2px 0;
    }

    .text-content {
      font-size: 14px;
    }
  }
}

.profile_page {
  &-menu {
    width: 250px;
  }

  &-menu {
    padding-left: 20px;

    .nav {
      a {
        padding: 5px 10px;
        height: 25px;
        display: flex;
        align-items: center;
      }
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: $dark;
      border-left: 2px solid transparent;

      &.active {
        color: #94c03d;
        border-left: 2px solid #94c03d;
      }
    }
  }

  .page_section-row {
    button {
      outline: none !important;

      span {
        outline: none !important;
      }
    }

    .page_section-button--text {
      color: #67af45;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.profile_page-title {
  font-size: 18px;
  font-weight: 500;
  border-bottom: 2px solid #888b8c;
}

.profile_page-section {
  padding: 0 10px;
  // border-bottom: 1px solid #888b8c;

  .language-selector {
    padding: 8px;
    width: 100%;
  }

  .page_section-row {
    // display: flex;
    margin-top: 25px;
    .section-row_label {
      min-width: 80px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 8px;
    }

    .section-row_label--small {
      font-size: 12px;
      font-weight: 400;
      min-width: 120px;
    }
  }

  .page_section-row-content {
    padding: 16px;
  }

  .page_section-row--sub {
    margin-bottom: -10px;
  }
}

.profile_page-settings {
  width: 100%;
  font-size: 12px;
  .profile_page-section:first-child {
    .page_section-row:first-child {
      margin-top: 0;
    }
  }
}

.profile_page-notifications {
  width: 100%;
  // padding: 20px;
  font-size: 12px;
  background: white;

  svg {
    font-size: 20px;
  }
}

.btn-green--bg {
  background-color: #888b8c !important;
}

.btn-green--bg.active {
  background-color: #94c03d !important;
}

.blank-line {
  height: 10px;
  width: 100%;
}

.profile_page-update-section {
  display: flex;
  .input-label {
    padding: 7px 7px 7px 0;
    font-weight: 500;
  }
  .input-field {
    width: 60%;
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiInputBase-root {
      height: 30px;
      input {
        font-size: 12px;
      }
    }
    .MuiFilledInput-underline.Mui-disabled:before {
      border-bottom-style: none;
      border-bottom: none;
    }
    .MuiFilledInput-root {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .set-edit {
    padding: 7px 0 7px 7px;
    a {
      text-decoration: none;
      color: #94c03d;
    }
  }
}
