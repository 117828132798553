/**
 * Re-style default OS scrollbar.
 */
#videoconference_page ::-webkit-scrollbar {
  background: transparent;
  width: 7px;
  height: $scrollHeight;
}

#videoconference_page ::-webkit-scrollbar-button {
  display: none;
}

#videoconference_page ::-webkit-scrollbar-track {
  background: transparent;
}

#videoconference_page ::-webkit-scrollbar-track-piece {
  background: transparent;
}

#videoconference_page ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.B_Body_Block {
  position: absolute;
  right: 10px;
  top: 10px;
  .remote_track {
    video {
      width: 150px;
    }
  }
}
.local_track {
  margin-left: -20px;
}
.local_track_controls {
  position: relative;
  min-height: calc(100vh - 174px);
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}
.content {
  &.videocall {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    overflow: hidden;
  }
}

.shortcuts-list {
  list-style: none;
}
