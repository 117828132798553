/*
* Global style variables
*/

/*
* Color Styles
*/
$headings: #1e2c36;
$paragraphs: #495660;
$primary: #1c5de1;
$icongraphy: #868e9a;
$elements1: #e4e4e4;
$elements2: #e6e6e6;
$background: #f9fafc;
$purple: #7747e3;
$green: #3cd278;
$red: #eb367e;
$yellow: #f7d68a;
$white: #ffffff;
$gray: #868e9a;
$blue: #1c5de1;
$blue-secondary: #182a63;
$blue1: #6344d3;
$blue2: #5f40cf;
$pastel-orange: #ffb347;
$hover-selected: #eaf8fb;
// font styles
$placeholder-color: #868e9a;
$oslo-grey: #888b8c;
$celery: #98cb54;
$sushi-color: #94c03d;
$cerise: #ee346b;
$gray-blue: #8ea4c6;

/*
* Text Styles
*/
$inputs: 14px;
$body-text: 14px;
$buttons: 14px;
$body-text: 14px;
$title-sm: 16px;
$title-md: 20px;
$title-bg: 24px;
$title-lg: 32px;

$title-md-font-weight: 600;
$subtitile-font-weight: 500;
$text-font-weight: 400;

// backgroud color
$black-color: #000000;
$login-background-color: #f9fafc;

// font-size
$font-18: 18px;
$font-16: 16px;
$font-14: 14px;
$font-12: 12px;

// line-height
$line-h16: 16px;
$line-h20: 20px;
$line-h24: 24px;
$line-h26: 26px;
$line-h44: 44px;

// button width
$btn-w126: 126px;

// button height
$btn-h56: 56px;
$btn-h44: 44px;
$btn-h28: 28px;

// button border radius
$btn-border-radius: 22px;

// letter spacing
$btn-letter-spacing1: 0.01em;
$lbl-letter-spacing1: 0.01em;
$title-letter-spacing: 0.0225em;
$subtitile-letter-spacing: 0.02625em;
// input text style
$input-font-style: 'Open Sans';
$input-font-w: 600;
$input-line-h: 20px;
$input-txt-font-ize: 14px;

// global backgroud style
//$bg: #C4C4C4;
$bg: #ffffff;
$box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
$content-block-shadow: $box-shadow;

$border: 1px solid #e4e4e4;
$border-color: #e4e4e4;
$border-radius: 4px;

//icons global style
$icon-sm: 20px;
$icon-box-shadow: 0px 2px 6px #0000002e;

// table var
$table-border-color: #e4e4e4;
$table-background-color: #ffffff;
$table-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
$table-border-radius: 4px;

// cards
$card-box-shadow: 0px 2px 2px #d1dfea;
$card-border-radius: 5px;
$card-box-shadow1: 0px 4px 12px #a1abc633;

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url(../assets/font/roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(../assets/font/roboto/Roboto-Medium.ttf) format('truetype');
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button,
select,
input,
textarea {
  outline: none;
}

.toggle-button-group {
  // button {
  //   color: #000;
  // }
  // button.Mui-selected {
  //   color: #fff;
  //   background: #94c03d !important;
  // }
}

.blank-space {
  padding: 5px 0;
}

.separate-horizontal-line {
  border-bottom: 1px solid #94c03d;
  margin: 25px auto;
  width: 97%;
}

.items-box-left {
  border-right: 1px solid #94c03d;
  padding-right: 16px !important;
}
.items-box-right {
  padding-left: 16px !important;
}

// .list-items-inner {
//   padding-left: 15px;
// }

.separate-vertical-line {
  height: 100%;
  border-right: 1px solid #94c03d;
}

.phone-number-input-field {
  //  This is to align with DOB
  &.register {
    margin-top: 4px;
  }

  .phone-number-input {
    width: 100% !important;
    height: 100% !important;
  }

  .special-label {
    background: #fafafa;
    font-size: 12px;
  }

  .phone-number-input:focus {
    border-color: #94c03d !important;
    box-shadow: 0 0 0 1px #94c03d !important;
  }

  .phone-number-input.phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .phone-number-input.phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  // .form-control + div:before {
  //   content: 'Cell Number' !important;
  //   left: 10px !important;
  // }

  // .form-control.phone-number-error + div:before {
  //   content: 'Cell Number' !important;
  //   left: 10px !important;
  //   color: #c70000 !important;
  // }
}

.phone-number-input-field-standard {
  padding: 4px 0px 0px 0px;
  margin-top: 8px;
  .phone-number-input {
    width: 100% !important;
    height: 54px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0 !important;
    padding-bottom: 7px !important;
  }
  .phone-number-input:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87) !important;
  }
  .phone-number-input:focus {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-shadow: none;
    border-bottom: 2px solid $sushi-color !important;
  }

  .special-label {
    top: 0;
    left: 0px;
    background: #fafafa;
    font-size: 12px;
  }

  .phone-number-input.phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .phone-number-input.phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  .selected-flag .flag {
    top: calc(50% + 5px);
  }

  // .form-control + div:before {
  //   content: 'Cell Number' !important;
  //   left: 10px !important;
  // }

  // .form-control.phone-number-error + div:before {
  //   content: 'Cell Number' !important;
  //   left: 10px !important;
  //   color: #c70000 !important;
  // }
}

.confirm-phone-number-input-field {
  .confirm-phone-number-input {
    width: 100% !important;
    height: 100% !important;
  }

  .confirm-phone-number-input:focus {
    border-color: #94c03d !important;
    box-shadow: 0 0 0 1px #94c03d !important;
  }

  .confirm-phone-number-input.confirm-phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .confirm-phone-number-input.confirm-phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.confirm-phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  .form-control + div:before {
    content: 'Confirm Cell Number' !important;
    left: 10px !important;
  }

  .form-control.confirm-phone-number-error + div:before {
    content: 'Confirm Cell Number' !important;
    left: 10px !important;
    color: #c70000 !important;
  }
}

.office-phone-number-input-field {
  .office-phone-number-input {
    width: 100% !important;
    height: 100% !important;
  }

  .office-phone-number-input:focus {
    border-color: #94c03d !important;
    box-shadow: 0 0 0 1px #94c03d !important;
  }

  .office-phone-number-input.office-phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .office-phone-number-input.office-phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.office-phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  .form-control + div:before {
    content: 'Office Phone' !important;
    left: 10px !important;
  }

  .form-control.office-phone-number-error + div:before {
    content: 'Office Phone' !important;
    left: 10px !important;
    color: #c70000 !important;
  }
}

.work-phone-number-input-field {
  .work-phone-number-input {
    width: 100% !important;
    height: 100% !important;
  }

  .work-phone-number-input:focus {
    border-color: #94c03d !important;
    box-shadow: 0 0 0 1px #94c03d !important;
  }

  .work-phone-number-input.work-phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .work-phone-number-input.work-phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.work-phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  .form-control + div:before {
    content: 'Work Phone' !important;
    left: 10px !important;
  }

  .form-control.work-phone-number-error + div:before {
    content: 'Work Phone' !important;
    left: 10px !important;
    color: #c70000 !important;
  }
}

.home-phone-number-input-field {
  .home-phone-number-input {
    width: 100% !important;
    height: 100% !important;
  }

  .home-phone-number-input:focus {
    border-color: #94c03d !important;
    box-shadow: 0 0 0 1px #94c03d !important;
  }

  .home-phone-number-input.home-phone-number-error:focus {
    box-shadow: 0 0 0 1px #c70000 !important;
  }

  .home-phone-number-input.home-phone-number-error {
    border-color: #c70000 !important;
    box-shadow: #c70000 !important;
  }

  .form-control:focus + div:before {
    color: #94c03d !important;
  }

  .form-control.home-phone-number-error:focus + div:before {
    color: #c70000 !important;
  }

  .form-control + div:before {
    content: 'Home Phone' !important;
    left: 10px !important;
  }

  .form-control.home-phone-number-error + div:before {
    content: 'Home Phone' !important;
    left: 10px !important;
    color: #c70000 !important;
  }
}

.input-error-custom-style {
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 400;
  line-height: 1.66;
  color: #c70000;
}

.react-bootstrap-table-pagination {
  width: 100%;
  padding: 0 20px 20px;
  .react-bootstrap-table-pagination-list {
    .pagination {
      float: right;
    }
    .page-item .page-link {
      color: #94c03d;
      height: 32px;
      min-width: 32px;
      text-align: center;
      margin: 0 3px;
      border-radius: 4px;
      border: solid 1px;
      background: transparent;
      text-decoration: none;
      &:hover {
        background-color: #eee;
        border-color: #ddd;
        border: solid 1px #94c03d;
      }
    }
    .active .page-link {
      background-color: #94c03d !important;
      color: #fff;
      &:hover {
        background-color: #94c03d;
        color: #fff;
      }
    }
  }
}
.MuiAvatarGroup-avatar {
  border: 2px solid #fafafa;
  margin-left: -8px;
  right: 10px;
  z-index: 1;
}

.page-setting-button {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  svg:hover {
    cursor: pointer;
    color: #868282;
  }
}

.box-scrollbar-style {
  // scroll for FF
  scrollbar-color: white hsla(0, 0%, 82.7%, 0.5);
  scrollbar-width: thin;
}

// scroll for chrome etc
.box-scrollbar-style::-webkit-scrollbar {
  width: 8px;
}
.box-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $gray-blue;
}
.box-scrollbar-style::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 82.7%, 0.5);
  border-radius: 8px;
  margin: 4px;
}

div.rhap_controls-section {
  div.rhap_main-controls {
    button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
      width: 50px;
      height: 50px;
      svg {
        font-size: 50px;
        color: $sushi-color;
      }
    }
  }
}
