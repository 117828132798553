/**
 * Various overrides outside of the filmstrip to style the app to support a
 * tiled thumbnail experience.
 */
.tile-view {
  /**
     * Let the avatar grow with the tile.
     */
  .avatar-container {
    max-height: initial;
    max-width: initial;
  }

  /**
     * Hide various features that should not be displayed while in tile view.
     */
  #dominantSpeaker,
  #filmstripLocalVideoThumbnail,
  #largeVideoElementsContainer,
  #sharedVideo,
  .filmstrip__toolbar {
    display: none;
  }

  #remoteConnectionMessage,
  .watermark {
    z-index: $filmstripVideosZ + 1;
  }

  /**
     * The follow styling uses !important to override inline styles set with
     * javascript.
     *
     * TODO: These overrides should be more easy to remove and should be removed
     * when the components are in react so their rendering done declaratively,
     * making conditional styling easier to apply.
     */
  #largeVideoElementsContainer,
  #remoteConnectionMessage,
  #remotePresenceMessage {
    display: none !important;
  }

  /**
     * Thumbnail popover menus can overlap other thumbnails. Setting an auto
     * z-index will allow AtlasKit InlineDialog's large z-index to be
     * respected and thereby display over elements in other thumbnails,
     * specifically the various status icons.
     */
  .remotevideomenu,
  .videocontainer__toptoolbar {
    z-index: auto;
  }
}
