.counter {
  width: 26px;
  height: 26px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(#dd1e42, #ff4992);
  border: 2px solid white;
}
