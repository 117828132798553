.text-editor {
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
}

.text-editor-signature {
  margin-top: 8px;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
}

.text-editor-signature > .quill {
  height: 20vh;
}

.text-editor-signature > .quill > .ql-container.ql-snow {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top: 0;
}

.text-editor > .quill {
  height: 20vh;
}

.ql-toolbar.ql-snow {
  border-radius: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 0;
}

.ql-container.ql-snow {
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.signature-toolbar {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom: 1px solid #ccc !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 20vh;
  overflow: auto;
}

/*
.ql-editor {
  overflow: hidden;
  overflow-y: scroll !important;
  min-height: 20vh;
  max-height: 20vh;
}

.ql-snow.ql-container {
  margin: 0 5px;
  border: none;
  align-items: center !important;
  justify-content: center !important;
}

.ql-toolbar {
  display: flex;
  width: 495px;
  min-height: 40px;
  padding: 1px 0 !important;
  background: #f8faff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center !important;
  justify-content: flex-end !important;
}

.ql-formats {
  display: flex;
  width: 100% !important;
  margin-left: 8px;
  margin-right: 0 !important;
  align-items: space-between;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #767676;
}

.ql-snow .ql-picker-label {
  width: 30px !important;
  height: auto !important;
}

.ql-toolbar .ql-fill {
  fill: #767676;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #767676 !important;
}

.ql-toolbar .ql-font {
  width: auto !important;
  background-image: url(../../assets/svg/arrowdownicon.svg);
  background-repeat: no-repeat;
  background-position-x: 35px;
  background-position-y: 7px;
}

.ql-toolbar .ql-header {
  max-width: 52px !important;
  max-height: 20px !important;
  display: flex;
  align-items: flex-start !important;
  background-image: url(../../assets/svg/arrowdownicon.svg);
  background-repeat: no-repeat;
  background-position-x: 45px;
  background-position-y: 7px;
}

.ql-toolbar .ql-size {
  width: 30px !important;
  font-size: 1em;
  display: flex;
  background-image: url(../../assets/svg/arrowdownicon.svg);
  background-repeat: no-repeat;
  background-position-x: 31px;
  background-position-y: 7px;
}

.ql-toolbar .ql-undo {
  width: 20px !important;
}

.ql-toolbar button {
  color: #767676;
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.ql-toolbar .ql-color {
  width: 25px !important;
  height: 20px !important;
}

.ql-toolbar .ql-align {
  width: 25px !important;
  height: 25px !important;
}

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}

#header .ql-picker-label,
#font .ql-picker-label {
  width: auto !important;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  max-height: 25px !important;
  font-size: 12px;
  padding: 0 1px;
}

#size .ql-picker-label {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  width: 30px !important;
  max-height: 25px !important;
  font-size: 12px;
  padding: 0 1px;
}

#header .ql-picker-label svg,
#size .ql-picker-label svg,
#font .ql-picker-label svg {
  width: 0px !important;
}

.ql-color,
.ql-align {
  background-image: url(../../assets/svg/arrowdownicon.svg);
  background-repeat: no-repeat;
  background-position-x: 20px;
  background-position-y: 7px;
}

.ql-picker-label:active,
.ql-picker-label:focus,
.ql-picker-label:hover,
.ql-picker-label.ql-active {
  background-color: #d4e5b9;
  color: #767676 !important;
  outline: none !important;
}

.ql-toolbar button:focus,
.ql-toolbar button:hover,
button.ql-active,
.ql-picker-item:hover,
.ql-picker-item:focus,
.ql-picker-item.ql-active {
  background-color: #d4e5b9;
  color: #767676 !important;
  outline: none !important;
}

.ql-picker-options {
  height: 100px;
  margin-top: -120px !important;
  overflow: hidden;
  overflow-y: scroll;
}

.ql-picker-item {
  font-size: 8px;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-picker-item:focus .ql-stroke,
.ql-picker-item.ql-active .ql-stroke,
.ql-picker-item:hover .ql-stroke,
.ql-picker-label:active .ql-stroke,
.ql-picker-label:focus .ql-stroke,
.ql-picker-label:hover .ql-stroke,
.ql-picker-label.ql-active .ql-stroke {
  color: black !important;
  stroke: black !important;
} */
