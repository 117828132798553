/**
 * Round badge.
 */
.badge-round {
  background-color: $toolbarBadgeBackground;
  border-radius: 50%;
  box-sizing: border-box;
  color: $toolbarBadgeColor;
  font-family: $baseFontFamily;
  font-size: 9px;
  font-weight: 700;
  line-height: 13px;
  min-width: 13px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
}

/**
 * TODO: when the old filmstrip has been removed, remove the "new-" prefix.
 */
.new-toolbox {
  bottom: calc((#{$newToolbarSize+8} * 2) * -1);
  left: 0;
  position: absolute;
  right: 0;
  transition: bottom 0.3s ease-in;
  width: 100%;

  &.visible {
    bottom: 0;
    .toolbox-background {
      bottom: 0px;
    }
  }

  &.no-buttons {
    display: none;
  }

  &.shift-right {
    margin-left: $sidebarWidth;
    width: calc(100% - #{$sidebarWidth});
  }

  .toolbox-background {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
    transition: bottom 0.3s ease-in;
    height: 160px;
    width: 100%;
    bottom: -160px;
    pointer-events: none;
    position: absolute;
    z-index: $toolbarBackgroundZ;
  }

  .toolbox-content {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
    margin-left: auto;
    margin-right: auto;
    padding: 20px 16px;
    position: relative;
    width: 100%;
    z-index: $toolbarZ;
    align-items: center;

    .button-group-center,
    .button-group-left,
    .button-group-right {
      display: flex;
      width: 33%;
    }
    .settings-button-container {
      outline: 0;
    }
    .button-group-center {
      justify-content: center;

      .toolbox-button {
        margin: 0 4px;
        .toolbox-icon {
          background-color: #fff;
          border-radius: 50%;
          border: 1px solid #d1dbe8;
          margin: 0;
          width: 38px;
          height: 38px;
          line-height: 38px;
          outline: 0;
          .amera-icon {
            display: flex;
            justify-content: center;
            svg {
              fill: #555555 !important;
            }
          }

          &:hover {
            background-color: #daebfa;
            border: 1px solid #daebfa;
          }

          &.toggled {
            background: #454545;
            border: 1px solid #454545;

            svg {
              fill: #fff !important;
            }

            &:hover {
              background-color: #5e6d7a;
            }
          }

          &.disabled,
          .disabled & {
            cursor: initial;
            color: #fff;
            background-color: #a4b8d1;
          }

          svg {
            fill: #5e6d7a !important;
          }
        }

        &:nth-child(2) {
          .toolbox-icon {
            background-color: $hangupColor;
            border: 1px solid $hangupColor;
            width: 40px;
            height: 40px;

            &:hover {
              background-color: $hangupColor;
            }

            svg {
              fill: #fff !important;
            }
            img {
              filter: invert(1);
            }
          }
        }
      }
    }

    .button-group-right {
      justify-content: flex-end;
      #ToolboxMoreMenu {
        &:after {
          display: none;
        }
        background-color: transparent;
        border: none;
      }
    }

    .dropdown-menu {
      padding: 0;
      min-width: 200px;
      .dropdown-item {
        align-items: center;
        color: #333333;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        padding: 5px;
        text-decoration: none;
        img {
          width: 20px;
          margin-right: 10px;
          &.image-rounded {
            border-radius: 50%;
            filter: none;
          }
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            width: 20px;
            height: 20px;
            fill: #8ea4c6;
            margin-right: 10px;
          }
        }

        &:hover {
          background-color: $primary;
          color: $defaultColor;
          svg {
            fill: #ffffff;
          }
        }

        &.unclickable {
          cursor: default;
        }
        &.unclickable:hover {
          background: inherit;
        }
        &.disabled {
          cursor: initial;
          color: #3b475c;
        }
      }

      .beta-tag {
        background: $overflowMenuItemColor;
        border-radius: 2px;
        color: $overflowMenuBG;
        font-size: 11px;
        font-weight: bold;
        margin-left: 8px;
        padding: 0 6px;
      }

      .overflow-menu-item-icon {
        margin-right: 10px;

        i {
          display: inline;
          font-size: 24px;
        }

        i:hover {
          background-color: initial;
        }

        img {
          max-width: 24px;
          max-height: 24px;
        }

        svg {
          fill: #b8c7e0 !important;
        }
      }

      .profile-text {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .toolbox-button {
      color: $toolbarButtonColor;
      cursor: pointer;
      display: inline-block;
      line-height: $newToolbarSize;
      margin: 0 8px;
      text-align: center;
      max-height: 40px;
      .btn.btn-primary {
        padding: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        &:after {
          display: none;
        }
      }
      .view-menu {
        a {
          padding-left: 25px;
        }
      }
    }

    .toolbar-button-with-badge {
      position: relative;

      .badge-round {
        bottom: -5px;
        font-size: 12px;
        line-height: 20px;
        min-width: 20px;
        pointer-events: none;
        position: absolute;
        right: -5px;
      }
    }

    .toolbox-button-wth-dialog {
      display: inline-block;
    }

    .toolbox-icon {
      display: flex;
      border-radius: 5px;
      flex-direction: column;
      font-size: 24px;
      height: $newToolbarSize;
      justify-content: center;
      width: $newToolbarSize;
      .amera-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 24px;
          fill: #ffffff !important;
        }
      }

      &:hover,
      &.toggled {
        background: $newToolbarButtonHoverColor;
      }

      &.disabled {
        cursor: initial !important;
        background-color: #a4b8d1 !important;

        svg {
          fill: #fff !important;
        }
      }
    }
  }
}

/**
 * START of fade in animation for main toolbar
 */
.fadeIn {
  opacity: 1;

  @include transition(all 0.3s ease-in);
}

.fadeOut {
  opacity: 0;

  @include transition(all 0.3s ease-out);
}
